/* eslint-disable import/no-unresolved */

import { createRef } from 'react';

import * as Sentry from '@sentry/browser';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';

import {
  login,
  loginClicked,
  oAuthLoginSuccess,
  showKycRefreshPopup,
  showReduceOnlyPopup,
  userLoginRedirection,
} from 'actions/user';
import { postMessageToMobileApp } from 'components/app/helpers';
import { redirectToEmailVerification } from 'components/Auth/Common/helpers';
import { OAUTH } from 'constants/api';
import { ERROR_CODES, IS_RN_WEBVIEW, IS_INDIAN_EXCHANGE } from 'constants/constants';
import {
  OAUTH_PROVIDERS,
  POST_MESSAGE_TO_MOBILE_EVENTS,
  STORAGE_KEYS,
} from 'constants/enums';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import routes from 'constants/routes';
import {
  trackMixpanelEvent,
  identifyUser,
  mixpanelStartRecording,
} from 'helpers/mixpanel-init';
import { addLogsInSentryAndMixpanel, addLogsToSentry } from 'helpers/sentry';
import {
  isEmpty,
  parseErrorMsg,
  resetTimeErrMsg,
  validateEmail as validateEmailPattern,
} from 'helpers/utils';
import i18n from 'i18n/config';
import Box from 'UIKit/Box';

import actions from './actions';
import {
  DeletedAccountExistsMessage,
  PasswordNotSetMessage,
  UserNotFoundMessage,
  UserNotFoundSignupLinkMessage,
  UserPresentOnGlobalExchangeMessage,
} from './partials/Messages';
import styles from './styles.module.scss';

export const canLogin = state => {
  const fieldsNotEmpty = state.email.value && state.password.value;
  const noError = !state.email.error && !state.password.error;
  const { isLoginBlocked } = state;
  return fieldsNotEmpty && noError && !isLoginBlocked;
};

export const setUserCountryCookie = country => {
  if (!IS_INDIAN_EXCHANGE && country) {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // Set cookie to expire in 1 year
    document.cookie = `user_login_country=${country
      .toLowerCase()
      .replace(/ /g, '_')}; path=/; expires=${date.toUTCString()}`;
  }
};

export const getPasswordValidation = (password, options) => {
  const validation = {
    error: false,
    errorMessage: '',
  };

  const passwordIsEmpty = isEmpty(password);

  // Validation on Submit Press.
  if (options?.submit) {
    if (passwordIsEmpty) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.password_empty');
    } else if (options.errorCode) {
      switch (options.errorCode) {
        case 'invalid_password': {
          const attemptsLeft =
            options.errorContext.max_attempts - options.errorContext.count;
          validation.error = true;
          validation.errorMessage =
            attemptsLeft < 2
              ? i18n.t('errors:custom.invalid_psw', {
                  count: attemptsLeft,
                })
              : i18n.t('errors:custom.invalid_psw_plural', {
                  count: attemptsLeft,
                });
          break;
        }
        case ERROR_CODES.BCRYPT_HASH_LIMIT:
          validation.error = true;
          validation.errorMessage = (
            <Box className={styles.hashLimitLoginErrMsg}>
              {i18n.t(`errors:custom.${ERROR_CODES.BCRYPT_HASH_LIMIT}_login`)}
              <Link
                data-testid="forgot-password-hash-limit-error"
                to={routes.forgotPassword}
                className={styles.hashLimitLoginErrMsgLink}
              >
                {' '}
                {i18n.t('errors:forgotPasswordRedirect')}
              </Link>
            </Box>
          );
          break;
        default:
          break;
      }
    }
    return validation;
  }

  return validation;
};

export const getEmailValidation = (email, options) => {
  const validation = {
    error: false,
    errorMessage: '',
  };

  const emailIsEmpty = isEmpty(email);
  const emailFormatIsInValid = !validateEmailPattern(email) && !isEmpty(email);

  // Validation on Submit Press.
  if (options?.submit) {
    if (emailIsEmpty) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.email_empty');
    } else if (emailFormatIsInValid) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.emailAddressInvalid');
    } else if (options.errorCode) {
      switch (options.errorCode) {
        case 'user_not_found': {
          validation.error = true;
          validation.errorMessage = <UserNotFoundSignupLinkMessage />;
          break;
        }
        case 'user_present_on_global_exchange': {
          validation.error = true;
          validation.errorMessage = <UserPresentOnGlobalExchangeMessage />;
          break;
        }
        case 'pending_verification': {
          validation.error = true;
          validation.errorMessage = `${i18n.t('auth:emailVerifyPending')} ${i18n.t(
            'auth:redirecting'
          )}`;
          break;
        }
        case ERROR_CODES.DELETED_ACCOUNT_EXISTS:
          validation.error = true;
          validation.errorMessage = (
            <DeletedAccountExistsMessage
              email={options?.errorContext?.email}
              userId={options?.errorContext?.user_id}
            />
          );
          break;
        default:
          break;
      }
    }
    return validation;
  }

  // Live Validation.
  if (emailFormatIsInValid) {
    validation.error = true;
  }

  return validation;
};

export const getValidatedState = (state, options) => {
  const newState = { ...state };

  newState.email = {
    ...newState.email,
    ...getEmailValidation(state.email.value, options),
  };

  newState.password = {
    ...newState.password,
    ...getPasswordValidation(state.password.value, options),
  };

  if (options.errorCode) {
    switch (options.errorCode) {
      case ERROR_CODES.LOGIN_BLOCKED: {
        const resetTime = options.errorContext.time
          ? Math.ceil(options.errorContext.time / 60)
          : 1;
        newState.password.errorMessage = resetTimeErrMsg(resetTime);
        newState.isLoginBlocked = true;
        break;
      }
      case ERROR_CODES.LOGIN_NOT_ACTIVE:
        newState.password.errorMessage = i18n.t('errors:user.login_not_active');
        break;
      case ERROR_CODES.FAILED_TO_FETCH:
        newState.password.errorMessage = i18n.t('auth:processBlocked');
        break;
      case ERROR_CODES.PASSWORD_NOT_SET:
        newState.password.errorMessage = <PasswordNotSetMessage />;
        break;
      default:
        break;
    }
  }

  return newState;
};

export const loginHandler = params => {
  const { state, dispatch, appDispatch, recaptchaRef, history } = params;
  const email = state.email.value;
  const password = state.password.value;
  const { deviceId } = state;

  appDispatch(loginClicked(email));

  recaptchaRef.current
    .executeAsync()
    .then(token => {
      mixpanelStartRecording();

      const recaptchaResponse = token;
      const apiBlockTimerRef = createRef();

      if (typeof recaptchaResponse !== 'string') {
        dispatch(actions.recaptchaError());
        recaptchaRef.current?.reset();
        return;
      }

      apiBlockTimerRef.current = setTimeout(() => {
        const commonTrackingData = {
          email,
          page_name: 'Sign-in',
          type: 'email',
        };
        trackMixpanelEvent('Login API Timeout', commonTrackingData);
        addLogsToSentry(
          {
            ...commonTrackingData,
            requestPayload: {
              email,
              deviceId,
              recaptchaResponse,
            },
          },
          'Login API Timeout'
        );
      }, 20000);

      appDispatch(
        login(email, password, deviceId, recaptchaResponse, {
          page_name: 'Sign-in',
          type: 'email',
        })
      )
        .then(data => {
          const {
            pre_2fa_token: pre2faToken,
            code,
            phone_number: phoneNumber,
            phone_number_added: phoneNumberAdded,
            phone_otp_required: phoneOTPRequired,
            country,
          } = data.result;

          trackMixpanelEvent(MIXPANEL_EVENT_NAMES.LOGIN.BUTTON_CLICKED, {
            type: 'email',
            page_name: 'Sign-in',
            email,
          });

          trackMixpanelEvent(MIXPANEL_EVENT_NAMES.LOGIN.BUTTON_CLICKED_SUCCESSFULLY, {
            type: 'email',
            page_name: 'Sign-in',
            email,
          });

          if (pre2faToken) {
            dispatch(
              actions.login_Pre2FA_Success({
                pre2faToken,
                code,
                phoneNumber,
                phoneNumberAdded,
                phoneOTPRequired,
                country,
              })
            );
          } else {
            setUserCountryCookie(country);
            identifyUser(data.result.id);
            trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_IN.SUCCESS, {
              page_name: 'Sign-in',
              $email: email,
              is_loggedin: true,
              type: 'email',
            });
            appDispatch(showKycRefreshPopup(true));
            appDispatch(showReduceOnlyPopup(true));
          }
        })
        .catch(errorResponse => {
          // Extract error
          const { error } = parseErrorMsg(errorResponse);

          // Reset Recaptcha
          recaptchaRef.current?.reset();

          trackMixpanelEvent(MIXPANEL_EVENT_NAMES.LOGIN.BUTTON_CLICKED, {
            page_name: 'Sign-in',
            type: 'email',
          });
          // Set errors in State.
          dispatch(
            actions.login_Pre2FA_Failure({
              errorCode: error?.code ?? ERROR_CODES.API_EMPTY_RESPONSE,
              errorContext: error?.context ?? null,
            })
          );

          // If email Verification is pending then redirect after 3 secs,
          // so that user will get time to see the redirection message.

          if (error?.code === 'pending_verification') {
            appDispatch(
              userLoginRedirection({
                pre_2fa_token: error?.context?.pre_2fa_token,
              })
            );
            setTimeout(() => {
              redirectToEmailVerification(email, history);
            }, 3000);
          }
        })
        .finally(() => {
          if (apiBlockTimerRef.current) {
            clearTimeout(apiBlockTimerRef.current);
          }
        });
    })
    .catch(() => {
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_IN.CAPTCHA_ERROR, {
        $email: email,
        page_name: 'Sign-in',
        type: 'email',
      });
      dispatch(actions.recaptchaError());
    });
};

const oAuthLogin = ({
  bodyData,
  userEmail,
  dispatch,
  history,
  provider,
  setOAuthLoginState,
  appDispatch,
  redirectURI,
}) => {
  const body = { ...bodyData };
  if (provider === OAUTH_PROVIDERS.APPLE) {
    body.redirect_uri = redirectURI;
  }

  const request = new Request(OAUTH.LOGIN, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  fetch(request)
    .then(res => res.json())
    .then(res => {
      const { error, success, result } = res;
      if (!success) {
        const {
          code: errorCode,
          jwt_token: jwtToken,
          context: errorContext = {},
        } = error;

        if (errorCode === ERROR_CODES.OAUTH_NOT_ACTIVE) {
          localStorage.setItem(STORAGE_KEYS.LINK_OAUTH_EMAIL, JSON.stringify(userEmail));
          localStorage.setItem(
            STORAGE_KEYS.LINK_OAUTH_JWT_TOKEN,
            JSON.stringify(jwtToken)
          );
          localStorage.setItem(
            STORAGE_KEYS.LINK_OAUTH_PROVIDER,
            JSON.stringify(provider)
          );
          history.push(routes.linkAccount);
        } else {
          const payload = {
            error: true,
            errorMessage: i18n.t(`errors:user.${errorCode}`),
            errorCode,
          };

          if (errorCode === ERROR_CODES.USER_NOT_FOUND) {
            payload.errorMessage = <UserNotFoundMessage />;
          }

          if (errorCode === ERROR_CODES.DELETED_ACCOUNT_EXISTS) {
            payload.errorMessage = (
              <DeletedAccountExistsMessage
                email={errorContext.email}
                userId={errorContext?.user_id}
              />
            );
          }

          if (errorCode === ERROR_CODES.LOGIN_BLOCKED) {
            payload.errorMessage = i18n.t(`errors:custom.${errorCode}`);
          }

          trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_IN.FAIL, {
            page_name: 'Sign-in',
            type: provider,
            $email: userEmail,
            error_code: errorCode,
          });
          dispatch(actions.oAuthLoginError(payload));

          if (
            [
              ERROR_CODES.LOGIN_BLOCKED,
              ERROR_CODES.USER_NOT_FOUND,
              ERROR_CODES.DELETED_ACCOUNT_EXISTS,
            ].includes(errorCode)
          ) {
            addLogsToSentry(
              {
                email: userEmail,
                body: bodyData,
                error,
              },
              'Login Oauth Handled Errors'
            );
          }
        }
      } else if (
        result.pre_2fa_token &&
        (result.code === 'mfa_code' || result.code === 'phone_otp')
      ) {
        dispatch(
          actions.login_Pre2FA_Success({
            pre2faToken: result.pre_2fa_token,
            code: result.code,
            phoneNumber: result.phone_number,
            phoneNumberAdded: result.phone_number_added,
            phoneOTPRequired: result.phone_otp_required,
            country: result.country,
          })
        );
      } else {
        setUserCountryCookie(result?.country);
        identifyUser(result.id);
        trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_IN.SUCCESS, {
          page_name: 'Sign-in',
          is_loggedin: true,
          type: provider,
          $email: result.email,
        });
        appDispatch(oAuthLoginSuccess(res));
        appDispatch(showKycRefreshPopup(true));
        appDispatch(showReduceOnlyPopup(true));
      }
    })
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setUser({
          email: userEmail,
          type: provider,
          error:
            error?.message === 'Failed to fetch'
              ? 'Failed to fetch'
              : JSON.stringify(error),
          requestPayload: body,
        });
        scope.setExtras(error);
        Sentry.captureMessage('OAuth Login Unhandled Error');
        Sentry.captureException(error);
      });
      dispatch(
        actions.oAuthLoginError({
          error: true,
          errorMessage: i18n.t(`errors:defaultMessage`),
          errorCode: 'defaultMessage',
        })
      );
    })
    .finally(() => {
      if (IS_RN_WEBVIEW) {
        postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.OAUTH_LOGOUT, {
          provider,
        });
        setOAuthLoginState();
      }
    });
};

export const oAuthLoginHandler = params => {
  mixpanelStartRecording();

  const {
    state,
    dispatch,
    appDispatch,
    history,
    accessToken,
    setOAuthLoginState,
    provider,
    redirectURI,
    idToken,
  } = params;

  dispatch(actions.setOAuthBtnLoader(true));

  const bodyData = {
    oauth_token: accessToken,
    device_id: state.deviceId,
    oauth: provider,
  };

  if (provider === OAUTH_PROVIDERS.GOOGLE) {
    fetch(`${OAUTH.GOOGLE_USER_INFO}${accessToken}`)
      .then(response => response.json())
      .then(response => {
        const userEmail = response.email;
        oAuthLogin({
          bodyData,
          userEmail,
          dispatch,
          history,
          provider,
          setOAuthLoginState,
          appDispatch,
        });
      })
      .catch(() => {
        addLogsInSentryAndMixpanel(
          {
            page_name: 'Sign-in',
            type: state.oAuth.provider,
          },
          MIXPANEL_EVENT_NAMES.SIGN_IN.GOOGLE_ACCESS_TOKEN_VALIDATION_FAILED
        );
        setOAuthLoginState();
        dispatch(
          actions.oAuthLoginError({
            error: true,
            errorMessage: i18n.t(`errors:defaultMessage`),
            errorCode: 'defaultMessage',
          })
        );
      });
  } else if (provider === OAUTH_PROVIDERS.APPLE) {
    const decoded = jwtDecode(idToken);
    const userEmail = decoded.email;

    oAuthLogin({
      bodyData,
      userEmail,
      dispatch,
      history,
      provider,
      setOAuthLoginState,
      appDispatch,
      redirectURI,
    });
  } else {
    dispatch(
      actions.oAuthLoginError({
        error: true,
        errorMessage: `${i18n.t(`errors:defaultMessage`)}`,
        errorCode: 'defaultMessage',
      })
    );
  }
};
