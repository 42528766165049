// Note: Use Enums when you want to use it as a typescript type as well as a constant

enum SIDE {
  BUY = 'buy',
  SELL = 'sell',
}

enum ORDER_TYPE {
  MARKET = 'market_order',
  LIMIT = 'limit_order',
}

enum MARGIN_MODE {
  ISOLATED = 'isolated',
  PORTFOLIO = 'portfolio',
  CROSS = 'cross',
}

enum STORAGE_KEYS {
  POSITIONS_PLACARD_MOBILE = 'positionsPlacardMobile',
  POSITIONS_PLACARD_TRADE = 'positionsPlacardTrade',
  POSITIONS_PLACARD_ALL_POSITIONS = 'positionsPlacardAllPositions',
  SCANTOLOGIN_PLACARD_MOBILE = 'scanToLoginPlacardMobile',
  MOENGAGE_REGISTERED = 'MOENGAGE_REGISTERED',
  TRACKIER_CLICK_ID = 'tr_click_id',
  MOE_DATA = 'MOE_DATA',
  DELTA_THEME = 'delta_theme',
  TRADINGVIEW_ACTIVE_RESOLUTION = 'trading.time.resolution',
  TRADINGVIEW_LAST_ACTIVE_CHART = 'trading.last.chartType',
  TRADINGVIEW_LAST_ACTIVE_CHART_TAB = 'last-chart-active-tab',
  TRADINGVIEW_IS_ORDERS_DISPLAY = 'tradingview.orders.display',
  PERSIST_ROOT = 'persist:root',
  ORDER_PLACING_BRACKET_ORDER_VISIBILITY = 'order_placing_bracket_order_visibility',
  IS_SAVE_REQUIRED = 'isSavedRequired',
  UPDATE_PASSWORD_POPUP = 'updatePasswordPopup',
  ACCOUNT_CREATED_MODAL_COUNT = 'accountCreatedModalCount',
  ACCOUNT_EQUITY_EYE_VISIBLE = 'accountEquityEyeVisible',
  ETH_OPTIONS_LIQUIDITY_STRIP = '@delta/ethOptionsLiquidityStrip',
  RESET_2FA_POPUP_SHOWN_KEY = 'reset2faPopUpShown',
  STATE_OF_RESIDENCE_MODAL_SHOWN_KEY = 'stateofResidenceModalShown',
  STATE_OF_RESIDENCE_MODAL_CLOSED_TIME = 'stateofResidenceModalClosedTime',
  ADD_BANK_AFTER_SUCCESSFUL_MODAL_MOBILE = 'hasAddBankSuccessfulModalShown',
  /* stores the value of strip which is currently viewed */
  INFORMATION_STRIP = '@delta/informationStrip',
  /** Stores whether to hide assets with zero balance in wallet menu.local storage being used because we need to keep this value in sync with web app and page */
  HIDE_ASSET_WITH_ZERO_BALANCE = '@delta/hideAssetWithZeroBalance',
  /**
   * @summary BIOMETRIC_AUTH_STATUS is used to store the status of current biometric preference.
   */
  BIOMETRIC_AUTH_STATUS = '@delta/biometricAuthStatus',
  BIOMETRICS_LOGOUT_ON_INIT = '@delta/biometricsLogoutOnInit',
  BIOMETRICS_LOGIN_METADATA = '@delta/biometricsLoginMetadata',
  BIOMETRICS_CONFIG = '@delta/biometricsConfig',

  PAGE_RELOADED = '@delta/pageReloaded',
  PREVIOUS_PATH_NAME = 'previousPathName',

  LINK_OAUTH_EMAIL = 'link-oauth-email',
  LINK_OAUTH_JWT_TOKEN = 'link-oauth-jwtToken',
  LINK_OAUTH_PROVIDER = 'link-oauth-provider',
  OAUTH_LANDING_PAGE_PRE2FA_EMAIL = 'oauth-landing-page-pre2fa-email',
  OAUTH_LANDING_PAGE_PRE2FA_CODE = 'oauth-landing-page-pre2fa-code',
  OAUTH_LANDING_PAGE_PRE2FA_TOKEN = 'oauth-landing-page-pre2fa-token',
  SHOW_BALANCE = 'account-balance',
  OAUTH_LANDING_PAGE_PHONE_OTP_REQUIRED = 'oauth-landing-page-phone-otp_required',
  OPTIONS_CHAIN_CUSTOMISABLE = 'options-chain-customisable',
  PRICE_ALERT_PLACARD = '@delta/price-alert-placard',

  /**
   * @summary When user gets approved or needs_review state from sdk we don't have to show Hyperverge cta for 5 minutes.
   * As internal state gets removed after refresh so maintaining in local storage.
   */
  HYPERVERGE_LAST_BLOCK_TIME = `@delta/hypervergeLastBlockTime`,

  OPTION_COMBOS_SLIDES = 'isOptionCombosSlidesShown',
  MOBILE_HOLDING_ACTIVE_TAB = 'mobileHoldingActiveTab',

  OPTIONS_CHAIN_STRIP_SEEN = 'isOptionsChainStripSeen',
  HOMEPAGE_TOP_SECTION_CARD_TYPE = 'homePageTopSectionCardType',
  PLACE_ORDER_QTY_INPUT_TRACK_TIME = '@delta/quantityInputLastTrackedTime',
  PLACE_ORDER_PRICE_INPUT_TRACK_TIME = '@delta/priceInputLastTrackedTime',

  SHOW_OPTIONS_ANALYTICS_ROTATE_MESSAGE = '@delta/showOptionsAnalyticsRotateMessage',
  MOBILE_POSITIONS_VIEW_MODE = 'mobilePositionsViewMode',
  HOLDINGS_TABLE_ACTIVE_TAB = 'holdings_table_active_tab',
  KYC_SHARE_CONSENT_POPUP_SEEN = '@delta/kycShareConsentPopupSeen',

  FEAT_TOGGLE_CURRENCY = 'toogle-currency-current-value',
  DEPOSIT_NO_ACTION_NEEDED_MESSAGE_SEEN = '@delta/depositNoActionNeededMessageseen',
  UTM_PARAMETERS = 'utmParameters',

  BRACKET_ORDER_TRIGGER_INDEX = 'bracket-order-trigger-index',
  /**
   * @description this is used to store the performance data for network requests which are not cached,
   * So that it can be send when headers data is present in cache storage */
  UN_CACHED_NETWORK_PERFORMANCE_DATA = '@delta/unCachedNetworkPerformanceData',
  INDIA_OR_GLOBAL_MODAL_CHOICE = '@delta/indiaOrGlobalModalChoice',

  SHOW_TRANSFER_FUND_MODAL = 'showTransferFundModal',

  TRADE_SUCCESS_CARD_CLOSE_TIME = 'trade_success_card_close_time',

  TRADE_SUCCESS_SHARE_CARD_DATA_LOADED = '@delta/tradeSuccessShareCardDataLoaded',
  IDFY_BLOCKED_TIMESTAMP_FOR_USER = '@delta/idfyBlockedTimestampForUser',
  USER_ID_POST_2FA_RESET = '@delta/setup2faModalUserId',
}

enum POST_MESSAGE_TO_MOBILE_EVENTS {
  USER_AUTHORIZED = 'USER_AUTHORIZED',
  SEND_APPS_FLYER_ID = 'SEND_APPS_FLYER_ID',
  OPEN_LINK_IN_EXTERNAL_BROWSER = 'OPEN_LINK_IN_EXTERNAL_BROWSER',
  APP_LOADED = 'APP_LOADED',
  BIOMETRICS_MESSAGES = 'BIOMETRICS_MESSAGES',
  LAUNCH_HYPERVERGE_SDK_KYC = 'LAUNCH_HYPERVERGE_SDK_KYC',
  LAUNCH_SHUFTIPRO_SDK_KYC = 'LAUNCH_SHUFTIPRO_SDK_KYC',
  OAUTH_BUTTON_CLICKED = 'OAUTH_BUTTON_CLICKED',
  OAUTH_LOGOUT = 'OAUTH_LOGOUT',
  FILE_DOWNLOAD_REQUEST = 'FILE_DOWNLOAD_REQUEST',
  LOGOUT = 'LOGOUT',
  LAUNCH_REACT_NATIVE_SHARE_OPEN = 'LAUNCH_REACT_NATIVE_SHARE_OPEN',
  LAUNCH_REACT_NATIVE_SHARE_SINGLE = 'LAUNCH_REACT_NATIVE_SHARE_SINGLE',
  STORE_BASE64_IMAGES_IN_MEDIA = 'STORE_BASE64_IMAGES_IN_MEDIA',
  LAUNCH_IN_APP_REVIEW = 'LAUNCH_IN_APP_REVIEW',
  CONSOLE = 'CONSOLE',
  TRACKIER_EVENT = 'TRACKIER_EVENT',
  LOGIN_CLICKED = 'LOGIN_CLICKED',
  SIGNUP_CLICKED = 'SIGNUP_CLICKED',
  LAUNCH_IDFY_KYC_WEBVIEW = 'LAUNCH_IDFY_KYC_WEBVIEW',
  CAMERA_PERMISSION = 'CAMERA_PERMISSION',
  THEME_CHANGE = 'THEME_CHANGE',
  APPSFLYER_SIGNUP = 'APPSFLYER_SIGNUP',
  SIGNUP_NOTIFICATION_SUCCESS = 'SIGNUP_NOTIFICATION_SUCCESS',
}

enum PLACE_ORDER_TYPE {
  LIMIT = 'limit',
  MARKET = 'market',
  STOP_MARKET = 'Stop Market',
  STOP_LIMIT = 'Stop Limit',
  TRAILING_STOP = 'Trailing Stop',
  TAKE_PROFIT_MARKET = 'Take Profit Market',
  TAKE_PROFIT_LIMIT = 'Take Profit Limit',
}

enum POSITION_TYPE {
  LONG = 'Long',
  SHORT = 'Short',
}

enum SCREEN_SIZES {
  XS = 576,
  SM = 768,
  MD = 1024,
  LG = 1280,
  XL = 1440,
}

enum CURRENCY_TYPE {
  INR = 'INR',
  USDT = 'USDT',
  BTC = 'BTC',
  USD = 'USD',
  DETO = 'DETO',
  ETH = 'ETH',
  XRP = 'XRP',
  BNS = 'BNS',
  SOL = 'SOL',
  USDC = 'USDC',
}

enum API_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  INITIAL = 'initial',
}

enum CURRENCY_SYMBOL {
  INR = '₹',
  USD = '$',
  USDT = '$',
}

enum OAUTH_PROVIDERS {
  APPLE = 'apple',
  GOOGLE = 'google',
}

enum ORDER_BOOK_TAB_TYPES {
  BOTH = 'both',
  SELL = 'sell',
  BUY = 'buy',
}

enum FUND_TYPE {
  MARKET_MAKING_POOL_FUND = 'market_making_pool_fund',
  STAKING_POOL = 'staking_pool',
  COVERED_OPTIONS = 'covered_options',
}

enum POSITION_ACTION_MODAL_MODES {
  CLOSE_MODE = 'CLOSE_MODE',
  CANCEL_MODE = 'CANCEL_MODE',
}

enum TRANSACTION_PARTNER_NAMES {
  DE_INTERNAL = 'de_internal',
  VAN = 'VAN',
}

// Methods by which user can recover their account
enum RECOVER_VERIFICATION_METHOD {
  FACE_AUTH = 'face_auth',
  KYC = 'kyc',
}

enum RECOVER_USER_ACCOUNT_KYC_LEVEL {
  ADDRESS_VERIFICATION = 'address_verification',
  IDENTITY_VERIFICATION = 'identity_verification',
}

enum KYC_VENDOR {
  IDFY = 'idfy',
  BLOCKPASS = 'blockpass',
  HYPERVERGE = 'hyperverge',
  SHUFTIPRO = 'shuftipro',
}

enum STATIC_MARKETING_OFFER_VALUES {
  SCALPER_OFFER = '1',
}

enum BANK_TYPE {
  EQ = 'EQ',
  IB = 'IB',
}
enum FACE_AUTH_STATUS {
  // When user has requested face auth flow.
  PENDING = 'pending',
  // User Successfully enrolled on hyperverge for face auth. Not used in fe.
  ENROLLED = 'enrolled',
  // final state for twofa flow , two_factor_reset is done
  TWO_FACTOR_RESET = 'two_factor_reset',
  // face auth failed by backend
  FAILED = 'failed',
  // final state for other flows .except twofa
  COMPLETED = 'completed',
}

enum GREEK_TYPES {
  DELTA = 'DELTA',
  GAMMA = 'GAMMA',
  THETA = 'THETA',
  VEGA = 'VEGA',
}

export {
  API_STATUS,
  CURRENCY_SYMBOL,
  CURRENCY_TYPE,
  FUND_TYPE,
  KYC_VENDOR,
  MARGIN_MODE,
  OAUTH_PROVIDERS,
  ORDER_BOOK_TAB_TYPES,
  ORDER_TYPE,
  PLACE_ORDER_TYPE,
  POSITION_ACTION_MODAL_MODES,
  POSITION_TYPE,
  POST_MESSAGE_TO_MOBILE_EVENTS,
  RECOVER_USER_ACCOUNT_KYC_LEVEL,
  RECOVER_VERIFICATION_METHOD,
  SCREEN_SIZES,
  SIDE,
  STORAGE_KEYS,
  TRANSACTION_PARTNER_NAMES,
  STATIC_MARKETING_OFFER_VALUES,
  BANK_TYPE,
  GREEK_TYPES,
  FACE_AUTH_STATUS,
};
