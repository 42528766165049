import { useEffect } from 'react';

import { postMessageToMobileApp } from 'components/app/helpers';
import {
  LOCAL_STORAGE_KEYS,
  AVAILABLE_THEMES,
  IS_INDIAN_EXCHANGE,
} from 'constants/constants';

import useLocalStorage from './useLocalStorage';

const INDIAN_THEME_MAPPING = {
  [AVAILABLE_THEMES.LIGHT]: AVAILABLE_THEMES.INDIAN_LIGHT,
  [AVAILABLE_THEMES.DARK]: AVAILABLE_THEMES.INDIAN_DARK,
};

const DEFAULT_THEME = IS_INDIAN_EXCHANGE
  ? AVAILABLE_THEMES.INDIAN_DARK
  : AVAILABLE_THEMES.DARK;

const useTheme = () => {
  const [storedValue, setStoredValue] = useLocalStorage(
    LOCAL_STORAGE_KEYS.DELTA_THEME,
    DEFAULT_THEME
  );

  useEffect(() => {
    if (IS_INDIAN_EXCHANGE) {
      if (storedValue in INDIAN_THEME_MAPPING) {
        setStoredValue(INDIAN_THEME_MAPPING[storedValue]);
      }
    }
  }, [storedValue, setStoredValue]);

  const toggleTheme = () => {
    if (IS_INDIAN_EXCHANGE)
      return setStoredValue(
        storedValue === AVAILABLE_THEMES.INDIAN_DARK
          ? AVAILABLE_THEMES.INDIAN_LIGHT
          : AVAILABLE_THEMES.INDIAN_DARK
      );
    return setStoredValue(
      storedValue === AVAILABLE_THEMES.DARK
        ? AVAILABLE_THEMES.LIGHT
        : AVAILABLE_THEMES.DARK
    );
  };

  const setTheme = (theme, shouldPostToMobile = true) => {
    setStoredValue(theme);
    if (shouldPostToMobile) {
      postMessageToMobileApp('THEME_CHANGE', { theme });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && storedValue) {
      document.documentElement.className = storedValue;
    }
  }, [storedValue]);

  const isLightTheme =
    storedValue === AVAILABLE_THEMES.LIGHT ||
    storedValue === AVAILABLE_THEMES.INDIAN_LIGHT;

  return {
    activeTheme: storedValue,
    isLightTheme,
    isDarkTheme: !isLightTheme,
    setTheme,
    toggleTheme,
    chartBackgroundColor: isLightTheme ? '#fafafa' : '#101013',
  };
};

export default useTheme;
