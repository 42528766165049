import {
  OPTIONS_CHAIN_UNDERLYING_ASSETS,
  OPTIONS_CHAIN_TOGGLE_PRICE_FILTER,
  OPTIONS_CHAIN_TOGGLE_EXPIRY_DATE,
  OPTIONS_CHAIN_CHART_NAVIGATION,
  TOGGLE_OPTIONS_CHAIN_VISUALISATION,
  OPTIONS_TRADE_SELECTED_COLUMN,
  OPTIONS_TRADE_CHANGE_COLUMN,
  OPTIONS_TRADE_CHANGE_COLUMN_SORT,
  OPTIONS_TRADE_CHANGE_MAX_CALL_OI,
  OPTIONS_TRADE_CHANGE_MAX_PUT_OI,
  OPTIONS_TRADE_CLOSEST_STRIKE,
  OPTIONS_CHAIN_UPDATE_OPTIONS_TYPE,
  OPTIONS_CHAIN_UPDATE_T_SHAPE_VIEW_TAB,
} from 'actionTypes/options_chain';
import createAction from 'helpers/createAction';
import type { OptionsChainTab, TshapeViewTab } from 'types/optionsChain';

export const updateOptionChainAssetSymbol = createAction(OPTIONS_CHAIN_UNDERLYING_ASSETS);

export const optionChainToggleFilter = createAction(OPTIONS_CHAIN_TOGGLE_PRICE_FILTER);

export const toogleOptionChainVisualisation = createAction(
  TOGGLE_OPTIONS_CHAIN_VISUALISATION
);

export const optionChainToggleExpiryDate = createAction(OPTIONS_CHAIN_TOGGLE_EXPIRY_DATE);

export const updateOptionChainSelectedOptionsType = createAction<OptionsChainTab>(
  OPTIONS_CHAIN_UPDATE_OPTIONS_TYPE
);

export const updateOptionChainSelectedTshapeViewTab = createAction<TshapeViewTab>(
  OPTIONS_CHAIN_UPDATE_T_SHAPE_VIEW_TAB
);

export const optionChainChartNavigation = createAction(OPTIONS_CHAIN_CHART_NAVIGATION);

export const changeOptionsSelectedColumns = createAction(OPTIONS_TRADE_SELECTED_COLUMN);

export const changeOptionsColumns = createAction(OPTIONS_TRADE_CHANGE_COLUMN);

export const changeOptionsColumnsSort = createAction(OPTIONS_TRADE_CHANGE_COLUMN_SORT);

export const changeMaxCallOi = createAction(OPTIONS_TRADE_CHANGE_MAX_CALL_OI);
export const changeMaxPutOi = createAction(OPTIONS_TRADE_CHANGE_MAX_PUT_OI);
export const changeClosestStrike = createAction(OPTIONS_TRADE_CLOSEST_STRIKE);
