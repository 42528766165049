/* eslint-disable @typescript-eslint/naming-convention */
enum TIME_FORMATS {
  /**
   * format -> 8th Jun 2022, 1:13:23 PM
   */
  MOBILE_HOLDINGS = 'Do MMM YY, h:mm:ss A',
  /**
   * format -> 12th Apr, 10:30 PM z
   */
  DD_MMM_h_mm_A_z = 'Do MMM, h:mm A z',
  /**
   * format -> 8th Jun
   */
  DD_MMM = 'DD MMM',
  /**
   * format -> 8th Jun 2022
   */
  Do_MMM_YYYY = 'Do MMM YYYY',
  /**
   * format -> 8 Jun 22
   */
  DD_MMM_YY = 'DD MMM YY',
  /**
   * format -> 8th, Jun 2022
   */
  Do_MMM_YYYY2 = 'Do, MMM YYYY',
  /**
   * 03 2022, week of the year
   */
  WW_YYYY = 'WW-YYYY',
  /**
   * 2018-02-09
   */
  YYYY_MM_DD = 'YYYY-MM-DD',
  /**
   * 09 02 2018
   */
  DD_MM_YYYY = 'DD MM YYYY',
  /**
   * 10 Nov 2022
   */
  DD_MMM_YYYY = 'DD MMM YYYY',
  /**
   * 2018-02-Th
   */
  yyyy_MM_dd = 'yyyy-MM-dd',
  /**
   * 26-04-2018 23:43
   */
  DD_MM_YYYY_HH_mm = 'DD-MM-YYYY HH:mm',
  /**
   * January 3rd 2022, 8:43:56 am
   */
  MMMM_Do_YYYY_h_mm_ss_a = 'MMMM Do YYYY, h:mm:ss a',
  /**
   * 10/11/22, 12:13 pm
   */
  DD_MM_YY_h_mm_a = 'DD/MM/YY[,] h:mm a',
  /**
   * 10/11/2022
   */
  DDMMYYYY = 'DD/MM/YYYY',
  /**
   * 10/11/22
   */
  DD_MM_YY = 'DD/MM/YY',
  /**
   * 10-Nov-2022 12:20:55 PM
   */
  DD_MMM_YYYY_h_mm_ss_A = 'DD-MMM-YYYY h:mm:ss A',
  // DD_MMM_YY = 'DD-MMM-YY', // 10-Nov-22
  /**
   * 9:23:38 PM
   */
  h_mm_ss_A = 'h:mm:ss A',
  /**
   * 11:23:38 PM
   */
  hh_mm_ss_A = 'hh:mm:ss A',
  /**
   * 11:23:38 pm
   */
  h_mm_ss_a = 'h:mm:ss a',
  /**
   * 12h:26m:21s
   */
  HHhmmmsss = 'HH[h]:mm[m]:ss[s]',
  /**
   * 12:29:41
   */
  HHmmss = 'HH:mm:ss',
  /**
   * 12.29
   */
  HHmm = 'HH.mm',
  /**
   * 12:29 AM
   */
  HHmmA = 'HH:mm A',
  /**
   * Nov 2022
   */
  MMM_YYYY = 'MMM YYYY',
  /**
   * 10 Nov 22
   */
  D_MMM_YY = 'D MMM YY',
  /**
   *  2022-11-Th
   */
  YYYY_MM_dd = 'YYYY-MM-dd',
  /**
   * 8:02 PM
   */
  LT = 'LT',
  /**
   * 08/16/2018
   */
  L = 'L',
  /**
   * 23:43
   */
  mmss = 'mm:ss',
  /**
   * Nov 10 2022
   */
  MMM_DD_YYYY = 'MMM DD YYYY',
  /**
   * 2022-11-10T13:06:30
   */
  YYYY_MM_DDTHH_mm_ss = 'YYYY-MM-DDTHH:mm:ss',
  /**
   *  02:03 PM z, 10-11-22
   */
  hh_mm_A_z_DD_MM_YY = 'hh:mm A z, DD-MM-YY',
  /**
   *  02:03 PM, 10-11-22
   */
  hh_mm_A_DD_MM_YY = 'hh:mm A, DD-MM-YY',
  /**
   * November
   */
  MMMM = 'MMMM',
  /**
   *  19th Dec 2022, 06:18
   */
  DO_MMM_YYYY_h_mm = `Do MMM YYYY, HH:mm`,
  /**
   *  Friday, 22 Feb 2021, 06:18
   */
  dddd_DD_MMM_YYYY_HHmm = 'dddd, DD MMM YYYY, HH:mm',
  /**
   * 170223
   */
  DDMMYY = 'DDMMYY',
  /**
   * 12:09
   */
  HH_mm = 'HH:mm',

  /**
   * format -> 28 Jan 2022, 12:30:30 pm
   */
  'DD_MMM_YYYY_hmmss_a' = 'DD MMM YYYY, h:mm:ss a',

  /**
   * format -> 28 Jan 2022, 12:30 pm
   */
  DD_MMM_YYYY_hmm_a = 'DD MMM YYYY, h:mm a',

  /**
   * format -> 16May2023
   */
  DDMMMYYYY = 'DDMMMYYYY',
  /**
   * Jun 10th 2023 14:38
   */
  MMM_Do_YYYY_HH_mm = 'MMM Do YYYY HH:mm',
  /**
   * January 3rd
   */
  MMMM_Do = 'MMMM Do',
  /**
   * 3rd Jan 12 pm
   */
  DD_MMM_h = 'Do MMM, h A',
  /**
   *10-06-23, 14:05
   */
  DD_MM_YY_HH_mm = 'DD-MM-YY, HH:mm',
  /**
   * 05/06/2024
   */
  dd_MM_yyyy = 'dd/MM/yyyy',
  /**
   * 12:05pm
   */
  hh_mm_a = 'hh:mma',
  /**
   * Mon, 12 Aug
   */
  ddd_DD_MMM = 'ddd, DD MMM',
}

// MS stands from milliseconds
const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = 60 * MS_IN_SECOND;
const MS_IN_HOUR = 60 * MS_IN_MINUTE;
const MS_IN_DAY = 24 * MS_IN_HOUR;
const MS_IN_WEEK = 7 * MS_IN_DAY;
const MS_IN_MONTH = 31 * MS_IN_DAY;
const MS_IN_YEAR = 12 * MS_IN_MONTH;

const timeDurations = [
  {
    milliseconds: MS_IN_YEAR,
    unit: 'years',
  },
  {
    milliseconds: MS_IN_MONTH,
    unit: 'months',
  },
  {
    milliseconds: MS_IN_WEEK,
    unit: 'weeks',
  },
  {
    milliseconds: MS_IN_DAY,
    unit: 'days',
  },
  {
    milliseconds: MS_IN_HOUR,
    unit: 'hours',
  },
  {
    milliseconds: MS_IN_MINUTE,
    unit: 'minutes',
  },
  {
    milliseconds: MS_IN_SECOND,
    unit: 'seconds',
  },
];

export {
  TIME_FORMATS,
  MS_IN_SECOND,
  MS_IN_MINUTE,
  MS_IN_HOUR,
  MS_IN_DAY,
  MS_IN_WEEK,
  MS_IN_MONTH,
  MS_IN_YEAR,
  timeDurations,
};
