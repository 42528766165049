/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
import { produce } from 'immer';

import { ADD_MARKET_PRODUCTS, SET_MARKETS_SELECTED_TAB } from 'actionTypes/markets';
import IMarket from 'types/IMarkets';

const initialState: IMarket = {
  marketProducts: [],
  showLoader: true,
  marketsSelectedTab: '',
};

const markets = produce((draft = initialState, action) => {
  switch (action.type) {
    case ADD_MARKET_PRODUCTS: {
      draft.marketProducts = action.payload;
      draft.showLoader = false;
      break;
    }

    case SET_MARKETS_SELECTED_TAB: {
      draft.marketsSelectedTab = action.payload;
      break;
    }

    default:
      return draft;
  }
});

export default markets;
