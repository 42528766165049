import { createSelector } from 'reselect';

import { SCREEN_SIZES } from 'constants/enums';
import { TIME_FORMATS } from 'constants/timeformats';
import { dateFormat, getUnixTimestamp, toUTC, getDateTime } from 'helpers/day';
import { prop } from 'helpers/ramda';
import { isMarketsPage, windowWidth } from 'helpers/utils';
import IState, { OptionsInfo } from 'types/Istore';

import { basketActiveUnderlyingAssetSelector } from './basketOrdersSelectors';
import { productsListSelector } from './tradeSelectors';

export const optionsChainSelector = (state: IState) => state.optionsChain;

export const optionsChainTogglePriceSelector = createSelector(
  [optionsChainSelector],
  prop('optionChainPriceToggle')
);

export const optionsChainSelectedDateSelector = createSelector(
  [optionsChainSelector],
  prop('expiryDate')
);

export const optionsChainSelectedAssetTypeSelector = createSelector(
  [optionsChainSelector],
  prop('assetSymbol')
);

export const optionsChainSelectedOptionsTypeSelector = createSelector(
  [optionsChainSelector],
  prop('selectedOptionsType')
);

export const optionsChainSelectedTshapeViewSelector = createSelector(
  [optionsChainSelector],
  prop('tShapeViewTab')
);

export const optionChainVisualisationSelector = createSelector(
  [optionsChainSelector],
  prop('optionChainVisualisation')
);

export const optionChainTradeSelectiveColumns = createSelector(
  [optionsChainSelector],
  prop('optionTradePageSelectedColumns')
);

export const optionsChainColumnsSelector = createSelector(
  [optionsChainSelector],
  prop('columns')
);

export const optionsChainSortSelector = createSelector(
  [optionsChainSelector],
  prop('isAscending')
);

export const optionsChainMaxCallOi = createSelector(
  [optionsChainSelector],
  prop('maxCallOi')
);

export const optionsChainMaxPutOi = createSelector(
  [optionsChainSelector],
  prop('maxCallOi')
);

export const optionsChainClosestStrike = createSelector(
  [optionsChainSelector],
  prop('closestStrike')
);

export const optionsChainProductListSelector = createSelector(
  [
    optionsChainSelectedDateSelector,
    optionsChainSelectedAssetTypeSelector,
    productsListSelector,
  ],
  (expiryDate, assetSymbol, productsList) => {
    const isMarkets = isMarketsPage();
    const isMobileView = windowWidth < SCREEN_SIZES.MD;
    const expiryFormatsAreSame = isMarkets || isMobileView;

    const filteredProducts = productsList.filter(product => {
      return (
        (expiryFormatsAreSame
          ? product.settlement_time === expiryDate
          : getUnixTimestamp(product.settlement_time) === Number(expiryDate)) &&
        product.underlying_asset.symbol === assetSymbol
      );
    });
    // console.log(
    //   'DEBUG optionsChainProductListSelector',
    //   expiryDate,
    //   filteredProducts,
    // );
    return filteredProducts;
  }
);

export const optionsInfoSelector = createSelector(
  [optionsChainSelector],
  prop('optionsInfo')
);

export const optionChainUnderlyingAssetListSelector = createSelector(
  [optionsInfoSelector],
  (optionsInfo: OptionsInfo[]) => {
    const uniqueAssets = new Set(
      optionsInfo
        .filter(
          item =>
            item.contract_type === 'call_options' || item.contract_type === 'put_options'
        )
        .flatMap(item => item.data?.map(d => d.asset) || [])
    );
    return Array.from(uniqueAssets);
  }
);

const getExpiryList = (optionsInfo: OptionsInfo[], selectedAsset: string) => {
  const uniqueExpiryDates = new Map();

  optionsInfo
    .filter(
      item =>
        item.contract_type === 'call_options' || item.contract_type === 'put_options'
    )
    .flatMap(item => item.data)
    .filter(d => d?.asset === selectedAsset)
    .forEach(d => {
      if (d?.settlement_time) {
        d.settlement_time.forEach(settlementTime => {
          if (!uniqueExpiryDates.has(settlementTime)) {
            uniqueExpiryDates.set(settlementTime, {
              value: settlementTime,
              label: dateFormat(
                toUTC(getDateTime(settlementTime)),
                TIME_FORMATS.Do_MMM_YYYY
              ),
            });
          }
        });
      }
    });
  return Array.from(uniqueExpiryDates.values());
};

export const expiryDropdownListofSelectedAssetSelector = createSelector(
  [optionsInfoSelector, optionsChainSelectedAssetTypeSelector],
  (optionsInfo, selectedAsset) => {
    const uniqueExpiryDates = new Map();

    optionsInfo
      .filter(
        item =>
          item.contract_type === 'call_options' || item.contract_type === 'put_options'
      )
      .flatMap(item => item.data)
      .filter(d => d?.asset === selectedAsset)
      .forEach(d => {
        if (d?.settlement_time) {
          d.settlement_time.forEach(settlementTime => {
            if (!uniqueExpiryDates.has(settlementTime)) {
              uniqueExpiryDates.set(settlementTime, {
                value: settlementTime,
                label: dateFormat(
                  toUTC(getDateTime(settlementTime)),
                  TIME_FORMATS.Do_MMM_YYYY
                ),
              });
            }
          });
        }
      });
    return Array.from(uniqueExpiryDates.values());
  }
);

export const expiryListBasketSelectedAssetSelector = createSelector(
  [optionsChainSelector, basketActiveUnderlyingAssetSelector],
  (optionsChain, basketActiveUnderlyingAsset) => {
    return getExpiryList(optionsChain.optionsInfo, basketActiveUnderlyingAsset);
  }
);

export const optionsChainProductTickerInfoSelector = createSelector(
  [optionsChainSelector],
  prop('optionsChain')
);

export const optionsChainTableSettlementTimeSelector = createSelector(
  [optionsChainSelector],
  optionsChain => {
    return optionsChain.chainTableSettlementTime;
  }
);
