/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
// TODO: Fix all these eslint issues

import memoize from 'fast-memoize';

import {
  BTC_MAX_PREC,
  LEVERAGE_PREC,
  MARGIN_TYPES,
  MIN_LEVERAGE,
} from 'constants/constants';
import { SIDE } from 'constants/enums';
import {
  compose,
  divide,
  indexBy,
  isEmpty,
  isNil,
  last,
  lensProp,
  lt,
  map,
  max,
  min,
  path,
  pipe,
  pluck,
  prop,
  reduce,
  reduceWhile,
  sum,
  view,
} from 'helpers/ramda';
import {
  addZeroesUntilCorrectPrecision,
  calcPrecision,
  cropAfterDecimals,
  decimalMultiplication,
  isOptions,
  numberCommaSeparator,
  settleTimeInSeconds,
  toFixed,
} from 'helpers/utils';
import { isTruthy } from 'ramdax';

function round_by_tick_size(_val, _tick_size, floor_or_ceil) {
  const val = Number(_val);
  const tick_size = Number(_tick_size);
  const tick_precision = calcPrecision(_tick_size);
  const remainder = val % tick_size;

  if (remainder === 0) {
    return val.toFixed(tick_precision);
  }

  if (floor_or_ceil == null) {
    floor_or_ceil = remainder >= tick_size / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + tick_size).toFixed(tick_precision);
    case 'floor':
      return (val - remainder).toFixed(tick_precision);
    default:
      return val.toFixed(tick_precision);
  }
}

function round_by_contract_size(_val, _contract_size, floor_or_ceil) {
  const val = Number(_val);
  const contractSize = Number(_contract_size);
  const cv_precision = calcPrecision(_contract_size);
  const remainder = val % contractSize;

  if (remainder === 0) {
    return val.toFixed(cv_precision);
  }

  if (floor_or_ceil == null) {
    floor_or_ceil = remainder >= contractSize / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + contractSize).toFixed(cv_precision);
    case 'floor':
      return (val - remainder).toFixed(cv_precision);
    default:
      return val.toFixed(cv_precision);
  }
}

const calculateOptionsMargin = ({
  size,
  side,
  price,
  leverage,
  spot_price,
  notional_type,
  contract_value,
}) => {
  const _price = notional_type === 'inverse' ? 1 / price : price;
  let margin;
  if (side === 'buy') {
    margin = Number(contract_value) * Math.abs(size) * _price; // premium
  } else {
    margin = Number(contract_value) * Math.abs(size) * (spot_price / leverage);
  }
  return margin;
};

const calculateMaxLossAtSettlementByLegs = legs => {
  if (!legs) return 0; // Since this is only required when using options spreads
  return reduce(
    (acc, leg) => {
      if (leg.side === 'sell') {
        return acc + Number(leg.strike_price);
      }
      return acc - Number(leg.strike_price);
    },
    0,
    legs
  );
};

const calculateOptionsCombosMargin = ({
  product,
  size,
  side,
  leverage,
  spotPrice,
  price,
}) => {
  const { product_specs, notional_type, contract_value } = product;
  const { legs } = product_specs;
  const max_loss_at_settlement = calculateMaxLossAtSettlementByLegs(legs);
  const optionsFormula = calculateOptionsMargin({
    size,
    side,
    price,
    leverage,
    spot_price: spotPrice,
    notional_type,
    contract_value,
  });
  const im =
    side === 'buy'
      ? optionsFormula
      : Math.min(
          Number(optionsFormula),
          (product_specs?.im_cash_flow_factor || 1) *
            Math.abs(Number(max_loss_at_settlement)) *
            Math.abs(size) *
            Number(contract_value)
        );
  return im;
};

export function calculateMargin(
  size,
  side,
  price, // limitprice
  leverage,
  mark_price,
  spot_price,
  product
) {
  const { product_specs, settlement_time, notional_type, contract_value, contract_type } =
    product;

  if (size === 0) {
    return 0.0;
  }

  switch (contract_type) {
    case 'interest_rate_swaps':
      // mark price should be divided by 100
      // const _price = product_type === 'inverse_future' ? 1 / spot_price : spot_price;
      const { floating_rate_max, floating_rate_min } = product_specs;
      const t = settleTimeInSeconds(settlement_time);

      if (size > 0) {
        const buyMargin =
          (Math.abs(size) *
            Number(contract_value) *
            (max(Number(mark_price), Number(price)) -
              min(Number(floating_rate_min), 0) / leverage) *
            t) /
          (365 * 86400 * 100);
        const finalBuymargin =
          notional_type === 'inverse' ? buyMargin / Number(spot_price) : buyMargin;
        return max(finalBuymargin, 0);
      }
      const sellMargin =
        (Math.abs(size) *
          Number(contract_value) *
          (max(Number(floating_rate_max), 0) / leverage -
            min(Number(mark_price), Number(price))) *
          t) /
        (365 * 86400 * 100);
      const finalSellMargin =
        notional_type === 'inverse' ? sellMargin / Number(spot_price) : sellMargin;
      return max(finalSellMargin, 0);

    case 'put_options':
    case 'call_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
      return calculateOptionsMargin({
        size,
        side,
        price,
        leverage,
        spot_price,
        notional_type,
        contract_value,
      });
    case 'options_combos':
      return calculateOptionsCombosMargin({
        product,
        size,
        side,
        leverage,
        spotPrice: spot_price,
        price,
      });
    case 'futures':
    case 'perpetual_futures': {
      const _price = notional_type === 'inverse' ? 1 / price : price;
      return ((Number(contract_value) * Math.abs(size)) / leverage) * _price;
    }
    case 'spreads':
      return (Number(contract_value) * Math.abs(size) * Number(spot_price)) / leverage;
    default:
      return (Number(contract_value) * Math.abs(size) * spot_price) / leverage;
  }
}

export function estimateMargin(
  product,
  buy_pairs,
  sell_pairs,
  leverage,
  mark_price,
  spot_price
) {
  let a = 0;
  let b = 0;
  let a_size = 0;
  let b_size = 0;

  const margins = [];
  const { contract_type } = product;
  while (a < buy_pairs.length && b < sell_pairs.length) {
    const sellUnfilledSize = path([b, 'unfilled_size'], sell_pairs);
    const buyUnfilledSize = path([a, 'unfilled_size'], buy_pairs);
    const l1 = path([a, 'limit_price'], buy_pairs);
    const l2 = path([b, 'limit_price'], sell_pairs);

    const size = Math.min(buyUnfilledSize - a_size, sellUnfilledSize - b_size);
    margins.push({
      size,
      l1,
      l2,
    });

    a_size += size;
    b_size += size;

    if (buyUnfilledSize === a_size) {
      a += 1;
      a_size = 0;
    }

    if (sellUnfilledSize === b_size) {
      b += 1;
      b_size = 0;
    }
  }

  while (a < buy_pairs.length) {
    margins.push({
      size: path([a, 'unfilled_size'], buy_pairs) - a_size,
      l1: path([a, 'limit_price'], buy_pairs),
      l2: null,
    });
    a += 1;
    a_size = 0;
  }

  while (b < sell_pairs.length) {
    margins.push({
      size: path([b, 'unfilled_size'], sell_pairs) - b_size,
      l1: null,
      l2: path([b, 'limit_price'], sell_pairs),
    });
    b += 1;
    b_size = 0;
  }

  // const comparator =
  //   product.notional_type === 'inverse' ? Math.min : Math.max;
  // const contract_type = product.contract_type;

  const totalMargin = reduce(
    (acc, { size, l1, l2 }) => {
      let limitPrice;
      let side;
      let margin;
      // calculate whether to block margin for buy or sell orders
      switch (contract_type) {
        case 'interest_rate_swaps':
          if (l2 && l1) {
            // calculate for both and take max.
            limitPrice = Number(l1);
            side = 'buy';
            const l1margin = calculateMargin(
              size,
              side,
              limitPrice,
              leverage,
              mark_price,
              spot_price,
              product
            );
            limitPrice = Number(l2);
            side = 'sell';
            const l2margin = calculateMargin(
              size,
              side,
              limitPrice,
              leverage,
              mark_price,
              spot_price,
              product
            );

            margin = Math.max(l1margin, l2margin);
          } else if (l1) {
            limitPrice = Number(l1);
            side = 'buy';

            margin = calculateMargin(
              size,
              side,
              limitPrice,
              leverage,
              mark_price,
              spot_price,
              product
            );
          } else if (l2) {
            limitPrice = Number(l2);
            side = 'sell';

            margin = calculateMargin(
              size,
              side,
              limitPrice,
              leverage,
              mark_price,
              spot_price,
              product
            );
          }
          break;
        case 'put_options':
        case 'call_options':
        case 'move_options':
        case 'turbo_put_options':
        case 'turbo_call_options':
        case 'options_combos':
          if (l2) {
            limitPrice = Number(l2);
            side = 'sell';
          } else {
            limitPrice = Number(l1);
            side = 'buy';
          }
          margin = calculateMargin(
            size,
            side,
            limitPrice,
            leverage,
            mark_price,
            spot_price,
            product
          );
          break;
        case 'futures':
        case 'perpetual_futures':
          if (product.notional_type === 'inverse') {
            limitPrice = Number(l1) || Number(l2);
            side = 'buy';
          } else {
            limitPrice = Number(l2) || Number(l1);
            side = 'sell';
          }

          margin = calculateMargin(
            size,
            side,
            limitPrice,
            leverage,
            mark_price,
            spot_price,
            product
          );
          break;
        case 'spreads':
          limitPrice = Number(l2) || Number(l1);
          side = 'sell';

          margin = calculateMargin(
            size,
            side,
            limitPrice,
            leverage,
            mark_price,
            spot_price,
            product
          );
          break;
        default:
          // keeping futures formula just in case we launch new contract type ui doesn't break;
          if (product.notional_type === 'inverse') {
            limitPrice = Number(l1) || Number(l2);
            side = 'buy';
          } else {
            limitPrice = Number(l2) || Number(l1);
            side = 'sell';
          }

          margin = calculateMargin(
            size,
            side,
            limitPrice,
            leverage,
            mark_price,
            spot_price,
            product
          );
          break;
      }

      acc += margin;
      return acc;
    },
    0.0,
    margins
  );

  return totalMargin;
}

function computeProductOrderMargin(
  product,
  position,
  buy_pairs,
  sell_pairs,
  leverage,
  mark_price,
  spot_price
) {
  // if (1) return 0; // for debug infinite while loop

  if (!position) {
    return estimateMargin(
      product,
      buy_pairs,
      sell_pairs,
      leverage,
      mark_price,
      spot_price
    );
  }
  // offset orders which will close the position
  const pairs = position.size > 0 ? sell_pairs : buy_pairs;
  let cancel_size = Math.abs(position.size);
  let pairs_iter = 0;
  const new_pairs = [];

  let iter_cancelled_size = 0;
  while (cancel_size > 0 && pairs_iter < pairs.length) {
    const size = Math.min(cancel_size, path([pairs_iter, 'unfilled_size'], pairs));
    iter_cancelled_size += size;
    cancel_size -= size;
    if (path([pairs_iter, 'unfilled_size'], pairs) === iter_cancelled_size) {
      pairs_iter += 1;
      iter_cancelled_size = 0;
    }
  }

  while (pairs_iter < pairs.length) {
    const unfilled_size =
      path([pairs_iter, 'unfilled_size'], pairs) - iter_cancelled_size;
    if (unfilled_size) {
      new_pairs.push({
        unfilled_size,
        limit_price: path([pairs_iter, 'limit_price'], pairs),
      });
    }
    pairs_iter += 1;
    iter_cancelled_size = 0;
  }

  const margin = estimateMargin(
    product,
    position.size < 0 ? new_pairs : buy_pairs,
    position.size > 0 ? new_pairs : sell_pairs,
    leverage,
    mark_price,
    spot_price
  );

  return margin;
}

// function throttle(fn, interval) {
//   let lastTime;
//   let result;
//   return function throttled() {
//     let timeSinceLastExecution = Date.now() - lastTime;
//     if (!lastTime || timeSinceLastExecution >= interval) {
//       result = fn.apply(this, arguments);
//       lastTime = Date.now();
//     }
//     return result;
//   };
// }

// export const _calculateExtraOrderMargin = throttle(
//   calculateExtraOrderMargin,
//   1000
// );

export function calculateExtraOrderMargin({
  mark_price,
  product,
  position,
  leverage,
  order_margin,
  user_buy_orders,
  user_sell_orders,
  side,
  size,
  limit_price = null, // null for market order
  spot_price,
}) {
  // console.log("DEBUG: calculateExtraOrderMargin",{
  //   mark_price,
  //   product,
  //   position,
  //   leverage,
  //   order_margin,
  //   user_buy_orders,
  //   user_sell_orders,
  //   side,
  //   size,
  //   limit_price,
  //   spot_price,
  // });
  if (isEmpty(leverage) || parseFloat(leverage) === 0) {
    return 0;
  }

  if (isNil(mark_price) && isNil(limit_price)) {
    return 0;
  }

  if (isTruthy(size) && !Number.isNaN(Number(size))) {
    const order = {
      unfilled_size: size,
      limit_price: limit_price || mark_price || 0.0,
    };
    user_buy_orders = side === 'buy' ? [...user_buy_orders, order] : user_buy_orders;
    user_sell_orders = side === 'sell' ? [...user_sell_orders, order] : user_sell_orders;
  }

  const newProductOrderMargin = computeProductOrderMargin(
    product,
    position,
    user_buy_orders,
    user_sell_orders,
    leverage,
    mark_price,
    spot_price
  );

  return Math.max(newProductOrderMargin - order_margin, 0.0);
}

function round(val, precision, floor_or_ceil) {
  if (floor_or_ceil) {
    const float_rep = parseFloat((0.1 ** precision).toFixed(precision));
    return round_by_tick_size(val, float_rep, floor_or_ceil);
  }
  return Number(val).toFixed(precision);
}

export function roundDown(value, precision) {
  return Number(`${Math.floor(`${value}e${precision}`)}e-${precision}`);
}

export function round_btc_max(val) {
  return parseFloat(val).toFixed(BTC_MAX_PREC);
}

export const restrictNoOfZerosAfterDecimal = (val, no_of_zeroes) => {
  return val
    .split('.')
    .map((el, i) => (i ? el.split('').slice(0, no_of_zeroes).join('') : el))
    .join('.');
};

export const getIrsUnPnl = (
  product,
  spot_price,
  mark_price,
  position,
  entry_price,
  created_at
) => {
  const { size } = position;
  const payoff = getIrsPayoff(size, mark_price, product, spot_price);
  const premium = getIrsPremium(size, entry_price, spot_price, product, created_at);
  return payoff + premium;
};

export const getIrsRoe = (position, pnl) => {
  const { margin } = position;
  const captialDeployed = Number(margin);
  return (pnl / captialDeployed) * 100;
};

export function getUnrealisedPnl(
  notional_type,
  contract_value,
  entry_price,
  exit_price,
  size,
  contract_type
) {
  switch (contract_type) {
    case 'call_options':
    case 'put_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos':
      const payOff = getPayOff(contract_value, size, exit_price);
      const premium = getPremium(contract_value, size, entry_price);
      return payOff + premium;
    case 'spreads':
      return (
        Number(contract_value) *
        parseInt(size, 10) *
        (Number(exit_price) - Number(entry_price))
      );
    default:
      if (notional_type === 'vanilla') {
        return (
          Number(contract_value) *
          parseInt(size, 10) *
          (Number(exit_price) - Number(entry_price))
        );
      }
      return (
        -1 *
        contract_value *
        parseInt(size, 10) *
        (1.0 / Number(exit_price) - 1.0 / Number(entry_price))
      );
  }
}

export function getChangePercentForPortfolioMargin(entryPrice, exitPrice, side) {
  const multiplier = side === SIDE.SELL ? -1 : 1;

  const result = (
    (Number(exitPrice) / Number(entryPrice) - 1) *
    100 *
    multiplier
  ).toFixed(2);

  return result;
}

export function getROE(unrealised_pnl, equity, product, entry_price, size) {
  const { contract_type, contract_value } = product;
  const premium = getPremium(contract_value, size, entry_price) * -1;

  switch (contract_type) {
    case 'put_options':
    case 'call_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos':
      const captialDeployed = Number(size) > 0 ? premium : Number(equity) + premium;
      return captialDeployed < 0 ? 0 : (unrealised_pnl / captialDeployed) * 100;
    case 'spreads':
      return ((unrealised_pnl * 100.0) / equity).toFixed(2);
    default:
      return ((unrealised_pnl * 100.0) / equity).toFixed(2);
  }
}

export const getTargetPrice = (roe, margin, product, size, entry_price) => {
  const { contract_type, contract_value, notional_type } = product;
  let captialDeployed;
  let targetPrice;
  // options
  if (isOptions(contract_type)) {
    const premium = getPremium(contract_value, size, entry_price) * -1;
    captialDeployed = size > 0 ? premium : Number(margin) + premium;
  } else {
    captialDeployed = Number(margin);
  }
  const unPnl = (roe / 100) * captialDeployed;
  if (notional_type === 'inverse') {
    targetPrice =
      (Number(entry_price) * Number(contract_value) * parseInt(size, 10)) /
      (Number(contract_value) * parseInt(size, 10) - Number(entry_price) * unPnl);
    if (!Number.isFinite(targetPrice)) {
      return '';
    }
    if (Number(targetPrice) < 0) {
      return '';
    }
  } else {
    targetPrice =
      unPnl / (parseInt(size, 10) * Number(contract_value)) + Number(entry_price);
  }

  if (Number(targetPrice) < 0) {
    return 0;
  }

  return targetPrice;
};

export function getEffectiveLeverage(
  product,
  entry_price,
  mark_price,
  spot_price,
  position,
  created_at
) {
  const { notional_type, contract_value, contract_type, product_specs, settlement_time } =
    product;
  const { floating_rate_min, floating_rate_max } = product_specs;
  const { size, margin } = position;
  const unrealised_pnl =
    contract_type === 'interest_rate_swaps'
      ? getIrsUnPnl(product, spot_price, mark_price, position, entry_price, created_at)
      : getUnrealisedPnl(
          notional_type,
          contract_value,
          entry_price,
          mark_price,
          size,
          contract_type
        );

  const positionVal = getPositionValue(
    notional_type,
    contract_value,
    mark_price,
    size,
    contract_type
  );

  // Dont remove this console . We gonna use this for debugging .
  // console.table([ {
  //   "notional_type" : notional_type,
  //   "contract_value" : contract_value,
  //   "entry_price":entry_price,
  //   "mark_price": mark_price,
  //   "size":size,
  //   "margin":margin,
  //   "unrealised_pnl":unrealised_pnl,
  //   "contract_type" : contract_type,
  //   "positionVal":positionVal,
  // }
  // ]);
  let leverage;
  const t = settleTimeInSeconds(settlement_time);
  switch (contract_type) {
    case 'interest_rate_swaps':
      if (size < 0) {
        leverage = (
          (positionVal * max(Number(floating_rate_max), 0) * (t / (365 * 86400 * 100))) /
          (Number(margin) + Number(unrealised_pnl)) /
          Number(spot_price)
        ).toFixed(LEVERAGE_PREC);
      } else {
        leverage = (
          (positionVal *
            -1 *
            min(Number(floating_rate_min), 0) *
            (t / (365 * 86400 * 100))) /
          (Number(margin) + Number(unrealised_pnl)) /
          Number(spot_price)
        ).toFixed(LEVERAGE_PREC);
      }
      break;
    case 'put_options':
    case 'call_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos':
      leverage = (
        (positionVal / (parseFloat(margin) + parseFloat(unrealised_pnl))) *
        Number(spot_price)
      ).toFixed(LEVERAGE_PREC);
      break;
    case 'spreads':
      leverage = (
        (positionVal * Number(spot_price)) /
        (parseFloat(margin) + parseFloat(unrealised_pnl))
      ).toFixed(LEVERAGE_PREC);
      break;
    default:
      leverage = (
        positionVal /
        (parseFloat(margin) + parseFloat(unrealised_pnl))
      ).toFixed(LEVERAGE_PREC);
      break;
  }

  return leverage;
}

export function contractValue(selectedProduct, markPrice, spotPrice) {
  const { is_quanto, contract_type, contract_value, quoting_asset, settling_asset } =
    selectedProduct;
  if (is_quanto) {
    if (isOptions(contract_type)) {
      return (contract_value * spotPrice).toFixed(quoting_asset.minimum_precision);
    }
    return (contract_value * markPrice).toFixed(settling_asset.precision);
  }
  return contract_value;
}

export function humanizedContractValue(value, pair) {
  if (value < 10 ** -3) {
    value *= 10 ** 3;
    pair = `m${pair}`;
  }

  value = parseFloat(value, 10) !== 1 ? `x ${value} ${pair}` : `${pair}`;
  return value;
}

export function contractUnitCurrency(selectedProduct) {
  if (selectedProduct.is_quanto) {
    return `${selectedProduct.contract_unit_currency}/${selectedProduct.quoting_asset.symbol}`;
  }
  return selectedProduct.contract_unit_currency;
}

export function minRequiredMargin(
  notional_type,
  contract_value,
  initial_margin,
  entry_price,
  position_size,
  contract_type
) {
  const position_value = getPositionValue(
    notional_type,
    contract_value,
    entry_price,
    position_size,
    contract_type
  );
  return (position_value * initial_margin) / 100.0;
}

/**
 * @param {product} -product object
 * @param {quantity}  -quantity of the product
 * @param {entry_price} -entry price of the position
 * @returns initial margin of position
 * @refer margin scaling in contracts page : https://www.delta.exchange/contracts#ADA
 */
function calculateInitialMargin(product, size, entry_price) {
  const {
    contract_value,
    contract_type,
    max_leverage_notional,
    initial_margin,
    initial_margin_scaling_factor,
    notional_type,
  } = product;
  const positionSize = getPositionValue(
    notional_type,
    contract_value,
    entry_price,
    size,
    contract_type
  );
  if (Number(positionSize) < Number(max_leverage_notional)) {
    return initial_margin;
  }
  return (
    Number(initial_margin) +
    Number(initial_margin_scaling_factor) *
      (Number(positionSize) - Number(max_leverage_notional))
  );
}

export function calculateMaxLeverage(initial_margin) {
  return 100 / Number(initial_margin);
}

function positionMaxRemovableMargin(
  product,
  size,
  entry_price,
  mark_price,
  position_margin,
  spot_price,
  maxLeverage
) {
  const {
    contract_value,
    contract_type,
    // initial_margin,
    settling_asset,
    notional_type,
  } = product;
  // console.log(contract_value,size,mark_price,spot_price,maxLeverage,product.contract_type,position_margin);
  if (contract_type === 'interest_rate_swaps') {
    const side = size < 0 ? 'sell' : 'buy';
    const margin = calculateMargin(size, side, 0, maxLeverage, 0, spot_price, product);
    return (Number(position_margin) - margin).toFixed(settling_asset.minimum_precision);
  }
  if (isOptions(contract_type)) {
    const premium = getPremium(contract_value, size, entry_price);
    const premiumAtMarkPrice = contract_value * Math.abs(size) * mark_price;

    const minRequiredMarginOptions = calculateOptionsMargin({
      size: Math.abs(size),
      side: size < 0 ? 'sell' : 'buy',
      mark_price,
      spot_price,
      leverage: maxLeverage,
      notional_type,
      contract_value,
    });

    const minRequiredMarginCombos = calculateOptionsCombosMargin({
      product,
      size,
      side: size < 0 ? 'sell' : 'buy',
      leverage: maxLeverage,
      spotPrice: spot_price,
      price: mark_price,
    });

    const minRequiredMargin =
      (contract_type === 'options_combos'
        ? minRequiredMarginCombos
        : minRequiredMarginOptions) +
      Math.max(Number(premium), Number(premiumAtMarkPrice));

    const removableMargin = Number(position_margin) - Number(minRequiredMargin);

    return removableMargin > 0
      ? removableMargin.toFixed(settling_asset.minimum_precision)
      : 0;
  }

  const initial_margin = calculateInitialMargin(product, size, entry_price);

  const unrealisedPnl = getUnrealisedPnl(
    notional_type,
    contract_value,
    entry_price,
    mark_price,
    size,
    contract_type
  );

  const minMargin = minRequiredMargin(
    notional_type,
    contract_value,
    initial_margin,
    entry_price,
    size,
    contract_type
  );

  let maxRemovable;
  if (unrealisedPnl > 0) {
    maxRemovable = Math.max(parseFloat(position_margin) - parseFloat(minMargin), 0);
  } else {
    maxRemovable = Math.max(
      parseFloat(position_margin) + unrealisedPnl - parseFloat(minMargin),
      0
    );
  }
  return maxRemovable.toFixed(settling_asset.minimum_precision);
}

// const getNotional = (
//   entry_price,
//   size,
//   contract_value,
//   notional_type
// ) => {
//   const value =
//     notional_type === 'future'
//       ? parseFloat(entry_price)
//       : 1.0 / parseFloat(entry_price);
//   return contract_value * value * Math.abs(size);
// };
/**
 *
 * @param {*} notional_type
 * @param {*} contract_value
 * @param {*} entry_price
 * @param {*} size
 * @param {*} contract_type
 * @returns notional in underlying for options and other contractype and notional in settling for futures contracttype
 */
export function getPositionValue(
  notional_type,
  contract_value,
  entry_price,
  size,
  contract_type
) {
  switch (contract_type) {
    case 'futures':
    case 'perpetual_futures':
      return getNotional(notional_type, contract_value, size, entry_price);
    case 'move_options':
    case 'put_options':
    case 'call_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'spreads':
    case 'interest_rate_swaps':
    case 'options_combos':
    default:
      return Math.abs(size) * Number(contract_value);
  }
}

export const getNotional = (notional_type, contract_value, size, price) => {
  if (notional_type === 'vanilla') {
    return Number(contract_value) * Math.abs(size) * Number(price);
  }

  return price === 0 || !price
    ? 0.0
    : (Number(contract_value) * Math.abs(size) * 1.0) / Number(price);
};

function getOrderValue(notional_type, contract_value, price, size, contract_type) {
  switch (contract_type) {
    case 'perpetual_futures':
    case 'futures':
      return getNotional(notional_type, contract_value, size, price);
    case 'move_options':
    case 'call_options':
    case 'put_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'interest_swaps':
    case 'options_combos':
      return Number(contract_value) * Math.abs(size);
    default:
      return getNotional(notional_type, contract_value, size, price);
  }
}

export function precision(a) {
  if (!Number.isFinite(Number(a))) {
    return 0;
  }
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}

export function percentageDifference(v1, v2) {
  const n1 = Number(v1);
  const n2 = Number(v2);
  const diff = n1 - n2;
  const ans = parseFloat(diff / Math.abs(n2)) * 100;
  return `${ans.toFixed(2)}%`;
}

export function calculate_depth(book) {
  const depth_book = [];
  const total_depth = book.reduce((agg, level, i) => {
    const depth = Number(agg) + Number(level.size);
    const preciseDepthValue = calcPrecision(depth) > 4 ? Number(depth.toFixed(4)) : depth;
    depth_book[i] = {
      ...level,
      depth_size: preciseDepthValue,
    };
    return agg + level.size;
  }, 0);

  return depth_book.map(level => ({
    ...level,
    share_percent: parseFloat((level.depth_size / total_depth) * 100, 10),
  }));
}

export function calculate_depth_percentage(book) {
  const total_depth = last(book) ? last(book).depth_size : 0;
  return book.map(level => ({
    ...level,
    share_percent: total_depth
      ? parseFloat((level.depth_size / total_depth) * 100, 10)
      : 0,
  }));
}

const reverseSide = x => (x === 'buy' ? 'sell' : 'buy');

export const convertOrderBook = (orderbook, side) => {
  return orderbook[reverseSide(side)];
};

const getSizes = pluck('size');
const getTotalSizes = compose(sum, getSizes);

/**
 *  If Value is
 * @param orderSize
 * @param orderBook
 * @param ticksize
 * @param productType
 * @param markPrice
 * @returns {*}
 */
export function calculateEstimatedMarketPrice(
  orderSize,
  orderBook,
  ticksize,
  productType,
  markPrice = null
) {
  const totalSize = getTotalSizes(orderBook);

  if (totalSize === 0 && markPrice) {
    return markPrice;
  }

  const actualSize = min(orderSize, totalSize);
  const isInverseContract = productType === 'inverse';

  const getPrice = (accPrice, bookPrice, size, actualSize) => {
    if (isInverseContract) {
      return 1 / accPrice + (1 / bookPrice) * (size / actualSize);
    }

    return accPrice + bookPrice * (size / actualSize);
  };

  const iterator = (acc, book) => {
    const size = min(book.size, actualSize - acc.sum);
    const calculatedPrice = getPrice(acc.price, book.price, size, actualSize);
    return {
      sum: acc.sum + size,
      price: isInverseContract ? 1 / calculatedPrice : calculatedPrice,
    };
  };

  const predicate = x => x.sum < actualSize;
  const initialPrice = isInverseContract ? Infinity : 0;
  const value = pipe(
    reduceWhile(predicate, iterator, { sum: 0, price: initialPrice }),
    prop('price')
  )(orderBook);

  const roundedValue = round_by_tick_size(value, ticksize, 'floor');

  return roundedValue;
}

export const calculateImpactPrice = (orderbook, markPrice, size, product) => {
  const { tick_size, notional_type } = product;

  const side = size > 0 ? 'buy' : 'sell';
  const orderbookBySide = orderbook[side];
  if (!orderbookBySide || isEmpty(orderbookBySide) || orderbookBySide.length === 0) {
    // if there is no orderbook we are not calculating the impact price
    // else, it will yield 0 and unrealised_pnl would be Infinity.
    return markPrice;
  }

  const impactPrice = calculateEstimatedMarketPrice(
    Math.abs(size),
    orderbookBySide,
    tick_size,
    notional_type
  );

  return impactPrice;
};

export function positionMinMargin(product, size, entry_price, mark_price) {
  const unrealisedPnl = getUnrealisedPnl(
    product.notional_type,
    product.contract_value,
    entry_price,
    mark_price,
    size
  );
  const minMargin = minRequiredMargin(
    product.notional_type,
    product.contract_value,
    product.initial_margin,
    entry_price,
    size,
    product.contract_type
  );
  return max(minMargin, minMargin - unrealisedPnl);
}

export function calculatePositionMarginUsingLeverage(position, mark_price, leverage) {
  const { product, entry_price, size } = position;
  const positionValue = getPositionValue(
    product.notional_type,
    product.contract_value,
    mark_price,
    size
  );

  const unrealisedPnl = getUnrealisedPnl(
    product.notional_type,
    product.contract_value,
    entry_price,
    mark_price,
    size
  );

  return positionValue / parseFloat(leverage, 10) - unrealisedPnl;
}

export function positionMaxMargin(position, mark_price, balances) {
  const { product, entry_price, size, margin } = position;
  const maxMargin =
    parseFloat(
      view(lensProp('available_balance'), prop(product.settling_asset.id, balances)),
      10
    ) + parseFloat(margin, 10);

  const unrealised_pnl = getUnrealisedPnl(
    product.notional_type,
    product.contract_value,
    entry_price,
    mark_price,
    size
  );
  const positionVal = getPositionValue(
    product.notional_type,
    product.contract_value,
    entry_price,
    size
  );
  const calculatedMaxMargin =
    positionVal / MIN_LEVERAGE - (unrealised_pnl < 0 ? 0 : unrealised_pnl);
  const max = round(
    min(calculatedMaxMargin, maxMargin),
    product.settling_asset.minimum_precision
  );
  return max;
}

// todo-h: Find usages of `getPositionValue()` and replace with calculateNotional() (generalized method)
/**
 * Notional is the value of order/fill/position in settling asset.
 * @param product
 * @param size
 * @param price
 * @returns {number}
 */
function calculateNotional(product, size, price) {
  const { notional_type, contract_value, contract_type } = product;
  if (isOptions(contract_type)) {
    return Math.abs(size) * contract_value;
  }
  if (notional_type === 'vanilla') {
    return Math.abs(size) * contract_value * parseFloat(price);
  }
  if (notional_type === 'inverse') {
    return (Math.abs(size) * contract_value) / parseFloat(price);
  }
  return 0;
}

/**
 * Returns precision of balance data
 *
 * For the numbers in USDC: show 2 decimal digits. If a number is smaller than 0.01 then switch to 4 decimal digits.
 *
 * For the numbers in BTC and ETH: show 4 decimal digits. If a number is smaller than 0.0001, then switch to 6 decimal digits.
 *
 * @param {Number|String} value
 * @param {USDC|BTC|ETH} contract
 */
export const getBalanceValPrecision = (value, contract) => {
  const numericValue = Number(value);
  let round = contract === 'USDC' ? 2 : 4;

  if (contract === 'USDC' && numericValue < 10 ** -2) {
    round = 4;
  } else if (contract !== 'USDC' && numericValue < 0.0001) {
    round = 6;
  }

  return round;
};

const convertBtcToUsd = (btcValue, spotPrice, precision = 2) => {
  const numericBtc = Number(btcValue);
  const numericSpotPrice = Number(spotPrice) || 0;
  const usdNumber = numericBtc * numericSpotPrice;
  return round(usdNumber, precision);
};

export function computeMaxQuantity({
  availableBalance,
  entryPrice,
  leverage,
  isVanillaProduct,
  totalPositionSize,
  isBuyActionState,
  commissionRate,
  contractValue = 1,
  product,
  spotPrice,
  markPrice,
}) {
  let maxQuantity = 0;
  const { product_specs, contract_type } = product;

  switch (contract_type) {
    case 'interest_rate_swaps':
      // time till settlement
      const t = settleTimeInSeconds(product.settlement_time) / 31536000;
      const { floating_rate_max, floating_rate_min } = product_specs;

      if (isBuyActionState) {
        maxQuantity =
          availableBalance /
          (((Math.max(entryPrice, Number(markPrice)) -
            Math.min(0, Number(floating_rate_min)) / leverage) *
            t *
            1) /
            100 +
            2 * commissionRate);
      } else {
        maxQuantity =
          availableBalance /
          (((-Math.min(entryPrice, Number(markPrice)) +
            Math.max(0, Number(floating_rate_max)) / leverage) *
            t *
            1) /
            100 +
            2 * commissionRate);
      }

      if (!isVanillaProduct) {
        maxQuantity *= spotPrice;
      }
      break;
    case 'put_options':
    case 'call_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos':
      if (isBuyActionState) {
        maxQuantity =
          availableBalance / (spotPrice * 2 * commissionRate + Number(entryPrice));
      } else {
        maxQuantity =
          availableBalance / (spotPrice / leverage + spotPrice * 2 * commissionRate);
      }
      break;
    case 'futures':
    case 'perpetual_futures':
      if (isVanillaProduct) {
        // maxQuantity = av_balance/ price / (1/leverage + 3 * comm_rate)
        maxQuantity = availableBalance / (1 / leverage + 2 * commissionRate) / entryPrice;
      } else {
        // maxQuantity = price * av_balance / (1/leverage + 3 * comm_rate)
        maxQuantity =
          (entryPrice * availableBalance) / (1 / leverage + 2 * commissionRate);
      }
      break;
    case 'spreads':
      maxQuantity = availableBalance / (1 / leverage + 2 * commissionRate) / spotPrice;
      break;
    default:
      break;
  }

  maxQuantity = Math.floor(maxQuantity / contractValue);

  maxQuantity += isBuyActionState
    ? Math.abs(Math.min(totalPositionSize, 0.0))
    : Math.max(totalPositionSize, 0.0);

  maxQuantity = isBuyActionState
    ? Math.min(
        product.position_size_limit - Math.max(totalPositionSize, 0.0),
        maxQuantity
      )
    : Math.min(
        product.position_size_limit - Math.abs(Math.min(totalPositionSize, 0.0)),
        maxQuantity
      );

  return maxQuantity;
}

export const getPositionSizeLimit = (product, spotPrice) => {
  const { notional_type, tick_size, contract_value, position_size_limit, contract_type } =
    product;
  switch (contract_type) {
    case 'interest_rate_swaps':
      if (notional_type === 'inverse') {
        const position =
          (Number(contract_value) / spotPrice) * Number(position_size_limit);
        return round_by_tick_size(position, tick_size, 'floor');
      }
      return Number(contract_value) * spotPrice * Number(position_size_limit);

    default:
      if (notional_type === 'inverse') {
        const position =
          (Number(contract_value) / spotPrice) * Number(position_size_limit);
        return round_by_tick_size(position, tick_size, 'floor');
      }
      return Number(contract_value) * spotPrice * Number(position_size_limit);
  }
};

export const maxNotionalAtSelectedLeverage = memoize(
  (leverage, selectedProduct, spotPrice) => {
    const {
      max_leverage_notional,
      initial_margin_scaling_factor,
      initial_margin,
      tick_size,
    } = selectedProduct;

    const positionLimit = getPositionSizeLimit(selectedProduct, spotPrice);
    // console.log(max_leverage_notional, initial_margin_scaling_factor, initial_margin, spotPrice, "positionLimit", positionLimit)
    const marginSelected = 100 / leverage;
    if (!Number(initial_margin_scaling_factor)) {
      return Math.min(positionLimit, max_leverage_notional);
    }
    const position =
      divide(
        Number(marginSelected) - Number(initial_margin),
        Number(initial_margin_scaling_factor)
      ) + Number(max_leverage_notional);
    return round_by_tick_size(Math.min(position, positionLimit), tick_size, 'floor');
  }
);

export const getOrderbookVanillaCalculations = (
  levels,
  contractSize,
  settlingSymbol,
  underlyingMinimumPrecision,
  settlingMinimumPrecision,
  quotingMinimumPrecision,
  tick_size
) =>
  (function() {
    const numericContractSize = Number(contractSize);
    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );

    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const underlyingNotionalSum = toFixed(underlyingMinimumPrecision)(
      numericContractSize * avgDenominator
    );

    const settlingAssetPrecision = isUsdStableCoin(settlingSymbol)
      ? 2
      : settlingMinimumPrecision;
    const settlingNotionalSum = toFixed(settlingAssetPrecision)(
      numericContractSize * avgNumerator
    );
    // console.log("DEBUG", levels, contractSize)

    return {
      average,
      underlyingNotionalSum: round_by_contract_size(
        underlyingNotionalSum,
        numericContractSize
      ),
      settlingNotionalSum,
      quotingNotionalSum: null,
    };
  });

export const getOrderbookInvserseCalculations = (
  levels,
  contractSize,
  settlingSymbol,
  underlyingMinimumPrecision,
  settlingMinimumPrecision,
  quotingMinimumPrecision,
  tick_size
) =>
  (function() {
    const numericContractSize = Number(contractSize);
    const avgNumerator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.size) / Number(level.price) : 0,
      0
    );
    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const settlingNotionalSum = toFixed(underlyingMinimumPrecision)(
      numericContractSize * avgDenominator
    );

    const quotingNotionalSum = round_by_contract_size(avgNumerator, numericContractSize);

    return {
      average,
      underlyingNotionalSum: null,
      settlingNotionalSum,
      quotingNotionalSum,
    };
  });

export const getOrderbookQuantoVanillaCalculations = (
  levels,
  contractSize,
  settlingSymbol,
  underlyingMinimumPrecision,
  settlingMinimumPrecision,
  quotingMinimumPrecision,
  tick_size,
  btcUsdSpotPrice,
  underlyingSpotPrice,
  contractType
) =>
  (function() {
    let underlyingNotionalSum;
    let settlingNotionalSum;
    const numericContractSize = Number(contractSize);

    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );
    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const settlingAssetPrecision = isUsdStableCoin(settlingSymbol)
      ? 2
      : settlingMinimumPrecision;

    switch (contractType) {
      case 'futures':
      case 'perpetual_futures':
        settlingNotionalSum = toFixed(settlingAssetPrecision)(
          numericContractSize * avgNumerator
        );
        underlyingNotionalSum = toFixed(underlyingMinimumPrecision)(
          (settlingNotionalSum *
            (isUsdStableCoin(settlingSymbol) ? 1 : btcUsdSpotPrice)) /
            underlyingSpotPrice
        );
        break;
      case 'move_options':
      case 'call_options':
      case 'put_options':
      case 'turbo_call_options':
      case 'turbo_put_options':
      case 'options_combos':
        underlyingNotionalSum = toFixed(underlyingMinimumPrecision)(
          avgDenominator * numericContractSize
        );
        settlingNotionalSum = toFixed(settlingAssetPrecision)(
          underlyingNotionalSum * underlyingSpotPrice
        );
        break;

      default:
        underlyingNotionalSum = toFixed(underlyingMinimumPrecision)(
          (settlingNotionalSum *
            (isUsdStableCoin(settlingSymbol) ? 1 : btcUsdSpotPrice)) /
            underlyingSpotPrice
        );
        break;
    }

    // if (contractType === 'future' || contractType === 'perpetual_futures') {
    //   settlingNotionalSum = (numericContractSize * avgNumerator).toFixed(
    //     settlingAssetPrecision
    //   );
    // }

    // if (isOptions(contractType) || isTurbo(contractType)) {
    //   underlyingNotionalSum = (avgDenominator * numericContractSize).toFixed(
    //     underlyingMinimumPrecision
    //   );
    // } else {
    //   underlyingNotionalSum = (
    //     (settlingNotionalSum *
    //       (isUsdStableCoin(settlingSymbol) ? 1 : btcUsdSpotPrice)) /
    //     underlyingSpotPrice
    //   ).toFixed(underlyingMinimumPrecision);
    // }

    // if (isOptions(contractType) || isTurbo(contractType)) {
    //   settlingNotionalSum = (underlyingNotionalSum * underlyingSpotPrice).toFixed(
    //     settlingAssetPrecision
    //   );
    // }

    return {
      average,
      underlyingNotionalSum,
      settlingNotionalSum,
      quotingNotionalSum: null,
    };
  });

export const getOrderbookIrsCalculations = (
  levels,
  contractValue,
  tick_size,
  underlyingSpotPrice,
  settlementTime
) =>
  (function() {
    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );
    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');
    const t = settleTimeInSeconds(settlementTime);
    const quotingNotionalSum = Number(contractValue) * avgDenominator;
    const spotPrice = Number(underlyingSpotPrice) || 1;
    const premium =
      ((Number(contractValue) / spotPrice) * average * (Number(t) / 31536000) * 1) / 100;

    return {
      average,
      underlyingNotionalSum: null,
      settlingNotionalSum: toFixed(6)(premium),
      quotingNotionalSum,
    };
  });

const isUsdStableCoin = symbol => {
  return !!(symbol === 'USD' || symbol === 'USDC' || symbol === 'USDT');
};

const getPremium = (contract_value, size, entry_price) => {
  return (
    -1 * decimalMultiplication(decimalMultiplication(contract_value, size), entry_price)
  );
};

const getIrsPremium = (size, entry_price, spot_price, product, created_at) => {
  const { settlement_time, contract_value } = product;
  const t = settleTimeInSeconds(settlement_time, created_at) / 31536000;
  return (
    -1 *
    ((((Number(entry_price) * 1) / 100) * t * Number(size) * Number(contract_value)) /
      Number(spot_price))
  );
};

export const getPayOff = (contract_value, size, mark_price) => {
  return Number(contract_value) * size * mark_price;
};

export const getIrsPayoff = (size, mark_price, product, spot_price) => {
  const { settlement_time, contract_value } = product;
  const t = settleTimeInSeconds(settlement_time) / 31536000;
  return (
    (((Number(mark_price) * 1) / 100) * t * Number(size) * Number(contract_value)) /
    Number(spot_price)
  );
};

export const getPositionSizeLimitByContractType = (product, spotPrice) => {
  // console.log(spotPrice, product.position_size_limit, product.contract_value)
  if (product.notional_type === 'inverse') {
    const position =
      (Number(product.contract_value) / spotPrice) * Number(product.position_size_limit);
    return round_by_tick_size(position, product.tick_size, 'floor');
  }
  return Number(product.contract_value) * spotPrice * Number(product.position_size_limit);
};

export const getPositionSizeLimitIRS = (product, spotPrice) => {
  if (product.notional_type === 'inverse') {
    const position =
      (Number(product.contract_value) / spotPrice) * Number(product.position_size_limit);
    return round_by_tick_size(position, product.tick_size, 'floor');
  }
  return Number(product.contract_value) * Number(product.position_size_limit);
};

export const ShowFeeCreditSection = balances => {
  let show = false;

  const { length } = balances;

  if (!length) {
    return show;
  }

  let i;

  for (i = 0; i < length; i++) {
    if (Number(balances[i].trading_fee_credit) !== 0) {
      show = true;
    }
  }

  return show;
};

function incrementAndDecrementTickSize(isIncrease, value, tickSize) {
  if (isIncrease) {
    value = Number(value) + Number(tickSize);
  } else {
    value = Number(value) - Number(tickSize);
  }
  return round_by_tick_size(value, tickSize);
}

function spotLeverage(
  leverage,
  floating_rate_min,
  floating_rate_max,
  side,
  launch_time,
  settlement_time
) {
  const t = settleTimeInSeconds(settlement_time, launch_time);
  // console.log("DEBUG", "leverage",leverage,
  // "vmin",floating_rate_min,
  // "vmax",floating_rate_max,
  // side,
  // launch_time,
  // settlement_time,
  // "time",t,
  // "result:",(leverage / Math.abs(Number(floating_rate_max))) * (31536000/ t) * 100)
  if (side === 'buy') {
    return Math.ceil(
      (leverage / Math.abs(Number(floating_rate_min))) * (31536000 / t) * 100
    );
  }
  return Math.ceil((leverage / Number(floating_rate_max)) * (31536000 / t) * 100);
}

const getLastPrice = (lastTrade, tickerData) => {
  return (lastTrade && lastTrade.price) || (tickerData && tickerData.close) || null;
};

// @ts-ignore-start
const computeBalances = data => {
  return indexBy(
    path(['asset', 'id']),
    map(wallet => {
      wallet.asset = wallet.asset || {};
      return {
        balance: wallet.balance,
        order_margin: wallet.order_margin,
        position_margin: max(
          Number(round(wallet.position_margin, wallet.asset.precision)),
          0
        ),
        commission: wallet.commission,
        interest_credit: wallet.interest_credit,
        available_balance: wallet.available_balance,
        trading_fee_credit: wallet.trading_fee_credit,
        // h: Precise value for free trade notional calculation
        trading_fee_credit_precise: wallet.trading_fee_credit,
        asset: wallet.asset,
        unvested_amount: wallet.unvested_amount,
        blocked_margin: wallet?.blocked_margin,
      };
    }, data)
  );
};

const computeBalancesBySymbol = data =>
  indexBy(
    path(['asset', 'symbol']),
    map(wallet => {
      wallet.asset = wallet.asset || {};
      return {
        balance: wallet.balance,
        order_margin: wallet.order_margin,
        position_margin: wallet.position_margin,
        commission: wallet.commission,
        interest_credit: wallet.interest_credit,
        available_balance: wallet.available_balance,
        trading_fee_credit: wallet.trading_fee_credit,
        // h: Precise value for free trade notional calculation
        trading_fee_credit_precise: wallet.trading_fee_credit,
        asset: wallet.asset,
        unvested_amount: wallet.unvested_amount,
        blocked_margin: wallet?.blocked_margin,
        balance_inr: wallet.balance_inr,
        available_balance_inr: wallet.available_balance_inr,
        referral_bonus: wallet.referral_bonus,
      };
    }, data)
  );
// @ts-ignore-end

const checkLowBalance = (product, margin, balanceState, marginMode, availableBalUSDT) => {
  if (marginMode === MARGIN_TYPES.CROSS) {
    return lt(Number(availableBalUSDT) || 0, Number(margin));
  }
  const symbol = product?.settling_asset?.symbol;
  const currencyBalance = path([symbol, 'available_balance'], balanceState);
  if (lt(Number(currencyBalance), Number(margin))) {
    return true;
  }
  return false;
};

const tradingFeeDiscount = (detoSpotPrice, mspPrice) => {
  const mspPercent = 0.25;
  const percentageDiscount = (1 - detoSpotPrice / mspPrice) * 100 * mspPercent;
  return Math.round(percentageDiscount);
};
/**
 * @summary
 * @param {*} contract_value
 * @param {*} size
 * @returns value in underlying terms
 * @returns value in rawData if isRawData is true
 */
const getSizeInUnderlyingTerms = ({
  contract_value,
  size,
  contract_type,
  minimum_precision,
  isRawData = false,
}) => {
  const precision =
    contract_type !== 'spot' ? calcPrecision(contract_value) : minimum_precision;
  const value = Number(contract_value) * Number(size);
  if (isRawData) {
    return value;
  }

  const croppedValue = cropAfterDecimals(value, precision);
  const intlFormat = numberCommaSeparator(croppedValue);
  return addZeroesUntilCorrectPrecision(intlFormat, precision);
};

const getUplPercentage = (markPrice, entry_price, size, precision) => {
  return round((markPrice / entry_price - 1) * (size > 0 ? 1 : -1) * 100, precision);
};

export const getFloor = num => Number(Math.floor(num));
/**
 * * EXPORT ALL FUNCTIONS BELOW
 */

const getSelectedAssetForPosition = product => {
  let selectedAsset = { ...product.settling_asset };
  switch (product.contract_type) {
    case 'put_options':
    case 'call_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos': {
      selectedAsset = { ...product.underlying_asset };
      break;
    }
    case 'interest_rate_swaps':
    case 'spreads': {
      selectedAsset = {
        symbol: product.contract_unit_currency,
        precision: calcPrecision(product.tick_size),
        minimum_precision: calcPrecision(product.tick_size),
      };
      break;
    }
    case 'spot': {
      selectedAsset = { ...product.quoting_asset };
      break;
    }
    default:
      selectedAsset = { ...product.settling_asset };
      break;
  }

  return selectedAsset;
};

export {
  calculateInitialMargin,
  calculateMaxLossAtSettlementByLegs,
  calculateNotional,
  checkLowBalance,
  computeBalances,
  computeBalancesBySymbol,
  convertBtcToUsd,
  getIrsPremium,
  getLastPrice,
  getOrderValue,
  getPremium,
  getSizeInUnderlyingTerms,
  getUplPercentage,
  incrementAndDecrementTickSize,
  isUsdStableCoin,
  positionMaxRemovableMargin,
  round,
  round_by_contract_size,
  round_by_tick_size,
  spotLeverage,
  tradingFeeDiscount,
  getSelectedAssetForPosition,
};
