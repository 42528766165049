import React, { SVGProps } from 'react';

const CircularExclamationWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="CircularExclamationWarning">
    <circle cx={9} cy={9} r={9} fill="#F24E53" />
    <path
      d="M9 4.667V10M9 14.333h.013"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircularExclamationWarning;
