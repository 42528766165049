import OTHER, {
  TAB_INACTIVE,
  TAB_REACTIVE,
  OPEN_ADD_ADDRESSMNGT_MODAL,
  SOCKET_DISCONNECTED_ON_PAGE_INACTIVE,
  SHOW_KYC_UPGRADE_STRIP,
  SHOW_CONVERT_BLOCKED_POPUP,
  SHOW_OTP_SUCCESS_POPUP,
  SET_COUNTRY_FROM_IP,
  TOGGLE_PHONE_VERIFICATION_MODAL,
  RESET_DISABLE_2FA_FACE_AUTH,
  SET_SHOULD_CLEAR_OTP_VALUES,
} from 'actionTypes/other';
import createReducer from 'helpers/createReducer';
import { add, timeNow } from 'helpers/day';
import IOther from 'types/IOther';

const returnNextMonthDate = () => add(timeNow(), 1, 'M');

const initialState: IOther = {
  isPageInactive: false,
  inActiveTime: returnNextMonthDate(),
  isTabInactive: false,
  isAddressManagementPopupOpen: false,
  socketDisconnectedOnPageInactive: false,
  tabReactiveTime: '',
  tabInactiveTime: '',
  showKycUpgradeStrip: true,
  showConvertBlockedPopup: false,
  showOtpSuccessPopup: false,
  countryFromIp: '',
  showPhoneVerificationModal: false,
  disable2faFaceAuth: {
    requestedEmail: '',
    requestedPasswordBase64: '',
  },
  shouldClearOtpValues: false,
};

export default createReducer(initialState, {
  [TAB_REACTIVE]: (state, action) => ({
    ...state,
    isPageInactive: false,
    isTabInactive: false,
    socketDisconnectedOnPageInactive: false,
    tabReactiveTime: action.payload.reactiveTime,
  }),
  [TAB_INACTIVE]: (state, action) => ({
    ...state,
    isPageInactive: true,
    inActiveTime: timeNow(),
    isTabInactive: true,
    tabInactiveTime: action.payload.inactiveTime,
  }),
  [SOCKET_DISCONNECTED_ON_PAGE_INACTIVE]: state => {
    return {
      ...state,
      socketDisconnectedOnPageInactive: true,
    };
  },
  [OPEN_ADD_ADDRESSMNGT_MODAL]: (state, action) => ({
    ...state,
    isAddressManagementPopupOpen: action.payload,
  }),
  [SHOW_KYC_UPGRADE_STRIP]: (state, action) => ({
    ...state,
    showKycUpgradeStrip: action.payload,
  }),
  [SHOW_CONVERT_BLOCKED_POPUP]: (state, action) => ({
    ...state,
    showConvertBlockedPopup: action.payload,
  }),
  [SHOW_OTP_SUCCESS_POPUP]: (state, action) => ({
    ...state,
    showOtpSuccessPopup: action.payload,
  }),
  [SET_COUNTRY_FROM_IP]: (state, action) => ({
    ...state,
    countryFromIp: action.payload,
  }),
  [TOGGLE_PHONE_VERIFICATION_MODAL]: (state, action) => ({
    ...state,
    showPhoneVerificationModal: action.payload,
  }),
  [OTHER.COUNTRY_FROM_IP.SUCCESS]: (state, action) => ({
    ...state,
    countryFromIp: action.result.result.country,
  }),
  [OTHER.TWO_FA_EMAIL_PASSWORD_VERIFY.SUCCESS]: (state, action) => {
    let encryptedPassword: string;

    try {
      encryptedPassword = btoa(action.payload.password);
    } catch (error) {
      encryptedPassword = '';
    }

    return {
      ...state,
      disable2faFaceAuth: {
        requestedEmail: action.payload.email,
        requestedPasswordBase64: encryptedPassword,
      },
    };
  },
  [RESET_DISABLE_2FA_FACE_AUTH]: state => ({
    ...state,
    disable2faFaceAuth: initialState.disable2faFaceAuth,
  }),
  [SET_SHOULD_CLEAR_OTP_VALUES]: (state, action) => ({
    ...state,
    shouldClearOtpValues: action.payload,
  }),
});
