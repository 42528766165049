/* eslint-disable camelcase */
import { ContractType, Product } from 'types/IProducts';

import {
  Payload,
  SIDE,
  calcPrecision,
  convertExponentialToDecimal,
  getOverlayPopoverCalculation,
  roundByPrecision,
  roundPriceLevel,
} from './utils';

interface ComputeOrderbookInterface {
  book: Array<{ limit_price: string; size: number; depth: string }>;
  selectedPriceClubbingValue: number;
  side: string;
  hideDepth: boolean;
  selectedProduct: Product;
  symbol: string;
}

const calcBookWithDepth = (sortedBook, totalDepth, selectedProduct, symbol) => {
  const bookWithDepth: Required<Payload>[] = [];
  sortedBook.forEach((order, idx) => {
    const depth = (bookWithDepth[idx - 1]?.depth || 0) + order.size;
    const { average, underlyingNotionalSum, settlingNotionalSum, quotingNotionalSum } =
      getOverlayPopoverCalculation(sortedBook.slice(0, idx + 1), selectedProduct)();
    const obj = {
      price: convertExponentialToDecimal(order.price),
      size: order.size,
      depth,
      depthPercentage: (depth / totalDepth) * 100,
      average,
      underlyingNotionalSum,
      settlingNotionalSum,
      quotingNotionalSum,
      symbol,
    };
    bookWithDepth.push(obj);
  });

  return bookWithDepth.sort((a, b) => {
    return Number(b.price) - Number(a.price);
  });
};

const computeOrderbook = ({
  book = [],
  selectedPriceClubbingValue,
  side,
  hideDepth,
  selectedProduct,
  symbol,
}: ComputeOrderbookInterface) => {
  const tickSize = selectedProduct?.tick_size;
  const precision = calcPrecision(tickSize);
  const computedBookObj = {};
  let totalDepth = 0;

  book.forEach(({ limit_price, size }) => {
    const price = parseFloat(limit_price);
    const clubbedPrice = Number(
      roundByPrecision(precision)(
        roundPriceLevel(
          Number(price),
          Number(convertExponentialToDecimal(selectedPriceClubbingValue)),
          side,
          Number(tickSize)
        )
      )
    );

    let clubbedSize = Number(size);
    if (computedBookObj[clubbedPrice]) {
      clubbedSize += Number(computedBookObj[clubbedPrice]?.size);
    }

    computedBookObj[clubbedPrice as number] = {
      price: clubbedPrice,
      size: clubbedSize,
    };
    totalDepth += Number(size);
  });

  const sortedBook = Object.values(computedBookObj).sort((a, b) => {
    return side === SIDE.SELL
      ? Number(a?.price) - Number(b?.price)
      : Number(b?.price) - Number(a?.price);
  });

  if (hideDepth) {
    return sortedBook;
  }

  return calcBookWithDepth(sortedBook, totalDepth, selectedProduct, symbol);
};

export const getComputedOrderbookFromSocket = (message, state$) => {
  const selectedProduct = state$.value?.trade?.selected_product;
  const selectedPriceClubbingValue =
    state$.value?.l2Orderbook?.selectedPriceClubbingValue;
  const contractType = state$?.value?.trade?.selectedContractType;
  const hideDepth =
    contractType === ContractType.CallOptions || contractType === ContractType.PutOptions;
  const buy = computeOrderbook({
    book: message.buy,
    selectedPriceClubbingValue,
    side: SIDE.BUY,
    hideDepth,
    selectedProduct,
    symbol: message.symbol,
  });
  const sell = computeOrderbook({
    book: message.sell,
    selectedPriceClubbingValue,
    side: SIDE.SELL,
    hideDepth,
    selectedProduct,
    symbol: message.symbol,
  });
  const product_id = message?.product_id;
  const type = message?.type;
  const symbol = message?.symbol;
  const last_sequence_no = message?.last_sequence_no;
  const last_updated_at = message?.last_updated_at;
  const timestamp = message?.timestamp;
  const error = message?.error;
  const sortedBids = message?.buy;
  const sortedAsks = message?.sell;
  const bestBid = sortedBids?.[0]?.limit_price;
  const bestAsk = sortedAsks?.[0]?.limit_price;
  const bestBidSize = sortedBids?.[0]?.size;
  const bestAskSize = sortedAsks?.[0]?.size;
  const spread = message?.spread;
  const percentage_spread = message?.percentage_spread;
  return {
    buy,
    sell,
    product_id,
    type,
    symbol,
    last_sequence_no,
    last_updated_at,
    timestamp,
    error,
    bestBid,
    bestAsk,
    bestBidSize,
    bestAskSize,
    spread,
    percentage_spread,
  };
};

export default computeOrderbook;
