import keyMirror from 'helpers/keymirror';

export enum AntiPhishingActionType {
  OPEN_CHANGE_CODE_MODAL = 'ACCOUNT/OPEN_CHANGE_CODE_MODAL',
  TRIGGER_VERIFICATION = 'ACCOUNT/TRIGGER_VERIFICATION',
  TRIGGER_VERIFICATION_FAILED = 'ACCOUNT/TRIGGER_VERIFICATION_FAILED',
  VERIFY_IDENTITY = 'ACCOUNT/VERIFY_IDENTITY',
  RESEND_EMAIL_CODE = 'ACCOUNT/RESEND_EMAIL_CODE',
  RESET = 'ACCOUNT/RESET',

  // #region API ACTION TYPES
  SEND_VERIFICATION_CODE = 'ACCOUNT/ANTI_PHISHING_SEND_VERIFICATION_CODE',
  UPDATE_CODE = 'ACCOUNT/ANTI_PHISHING_UPDATE_CODE',
  // #endregion
}

export enum WithdrawalHistoryActionType {
  FETCH_DATA = 'FETCH_DATA',
}

export enum BankDetailsActionType {
  // #region API ACTION TYPES
  GET_BANK_ACCOUNTS = 'ACCOUNT/GET_BANK_ACCOUNT_LIST',
  ADD_ACCOUNT_SEND_OTP = 'ACCOUNT/ADD_BANK_ACCOUNT_SEND_OTP',
  ADD_ACCOUNT_RESEND_OTP = 'ACCOUNT/ADD_BANK_ACCOUNT_RESEND_OTP',
  ADD_ACCOUNT_VERIFY_OTP = 'ACCOUNT/ADD_BANK_ACCOUNT_VERIFY_OTP',
  ADD_ACCOUNT_CHECK_STATUS = 'ACCOUNT/ADD_BANK_ACCOUNT_CHECK_STATUS',
  DELETE_BANK_ACCOUNT = 'ACCOUNT/DELETE_BANK_ACCOUNT',
  GET_BANK_DETAILS = 'ACCOUNT/GET_BANK_DETAILS',
  // #endregion
}

export enum KycImportActionType {
  IMPORT_KYC_FROM_GLOBAL = 'ACCOUNT/IMPORT_KYC_FROM_GLOBAL',
}

export enum FiatBankDetailsActionTypeIndia {
  // #region API ACTION TYPES
  GET_FIAT_BANK_DETAILS = 'ACCOUNT/GET_FIAT_BANK_DETAILS',
  // #endregion
}

export enum DepositsActionTypeIndia {
  // #region API ACTION TYPES
  NEW_DEPOSITS = 'ACCOUNT/NEW_DEPOSITS',
  GET_DEPOSIT_HISTORY = 'ACCOUNT/GET_DEPOSIT_HISTORY',
  CONFIRM_DEPOSITS = 'ACCOUNT/CONFIRM_DEPOSITS',
  REFETCH_DEPOSIT_HISTORY = 'ACCOUNT/REFETCH_DEPOSIT_HISTORY',
  WHITELIST_UPI_ID = 'ACCOUNT/WHITELIST_UPI_ID',
  GENERATE_UPI_PAYMENT_URL = 'ACCOUNT/GENERATE_UPI_PAYMENT_URL',
  GET_UPI_DEPOSIT_STATUS = 'ACCOUNT/GET_UPI_DEPOSIT_STATUS',
  GET_WHITELISTED_UPI_IDS = 'ACCOUNT/GET_WHITELISTED_UPI_IDS',
  SET_ACTIVE_UPI_ID = 'ACCOUNT/SET_ACTIVE_UPI_ID',
  CLAIM_DEPOSIT = 'ACCOUNT/CLAIM_DEPOSIT',
  SUBMIT_REFUND_DETAILS = 'ACCOUNT/SUBMIT_REFUND_DETAILS',
  GET_REFUND_DETAILS = 'ACCOUNT/GET_REFUND_DETAILS',
  SET_ACTIVE_DEPOSIT_PARTNER = 'ACCOUNT/SET_ACTIVE_DEPOSIT_PARTNER',
  // #endregion
}

export enum WithdrawalActionTypeIndia {
  // #region API ACTION TYPES
  NEW_WITHDRAWAL = 'ACCOUNT/NEW_WITHDRAWAL',
  WITHDRAWAL_CODE_VERIFICATION = 'ACCOUNT/WITHDRAWAL_CODE_VERIFICATION',
  GET_WITHDRAWAL_HISTORY = 'ACCOUNT/GET_WITHDRAWAL_HISTORY',
  REFETCH_WITHDRAWAL_HISTORY = 'ACCOUNT/REFETCH_WITHDRAWAL_HISTORY',
  // #endregion
}

export enum SignalTradingActionType {
  GET_SOURCES = 'ACCOUNT/GET_SOURCES',
  GET_VERIFICATION_CODE = 'ACCOUNT/GET_VERIFICATION_CODE',
  GET_USER_TRADING_WEBHOOK = 'ACCOUNT/GET_USER_TRADING_WEBHOOK',
  GET_ACTIVE_WEBHOOKS = 'ACCOUNT/GET_ACTIVE_WEBHOOKS',
  SEND_VERIFICATION_CODE = 'ACCOUNT/SEND_VERIFICATION_CODE',
  CREATE_WEBHOOK = 'ACCOUNT/CREATE_WEBHOOK',
  DELETE_WEBHOOK = 'ACCOUNT/DELETE_WEBHOOK',
}

const ActionTypes = {
  // #region Anti Phishing Code
  [AntiPhishingActionType.SEND_VERIFICATION_CODE]: null,
  [AntiPhishingActionType.UPDATE_CODE]: null,
  // #endregion

  // #region FiatWithdrawalHistory
  [WithdrawalHistoryActionType.FETCH_DATA]: null,
  // #endregion

  // #region Bank Details
  [BankDetailsActionType.GET_BANK_ACCOUNTS]: null,
  [BankDetailsActionType.ADD_ACCOUNT_SEND_OTP]: null,
  [BankDetailsActionType.ADD_ACCOUNT_RESEND_OTP]: null,
  [BankDetailsActionType.ADD_ACCOUNT_VERIFY_OTP]: null,
  [BankDetailsActionType.ADD_ACCOUNT_CHECK_STATUS]: null,
  [BankDetailsActionType.DELETE_BANK_ACCOUNT]: null,
  // #endregion

  // #region Kyc
  [KycImportActionType.IMPORT_KYC_FROM_GLOBAL]: null,
  // #endregion

  // #region Deposit
  [FiatBankDetailsActionTypeIndia.GET_FIAT_BANK_DETAILS]: null,
  // #endregion

  // #region Deposit
  [DepositsActionTypeIndia.NEW_DEPOSITS]: null,
  [DepositsActionTypeIndia.GET_DEPOSIT_HISTORY]: null,
  [DepositsActionTypeIndia.CONFIRM_DEPOSITS]: null,

  [DepositsActionTypeIndia.GET_WHITELISTED_UPI_IDS]: null,
  [DepositsActionTypeIndia.WHITELIST_UPI_ID]: null,
  [DepositsActionTypeIndia.GENERATE_UPI_PAYMENT_URL]: null,
  [DepositsActionTypeIndia.SET_ACTIVE_UPI_ID]: null,
  [DepositsActionTypeIndia.CLAIM_DEPOSIT]: null,
  [DepositsActionTypeIndia.SUBMIT_REFUND_DETAILS]: null,
  [DepositsActionTypeIndia.GET_REFUND_DETAILS]: null,
  [DepositsActionTypeIndia.SET_ACTIVE_DEPOSIT_PARTNER]: null,
  // #endregion

  // #region Withdrawals
  [WithdrawalActionTypeIndia.NEW_WITHDRAWAL]: null,
  [WithdrawalActionTypeIndia.WITHDRAWAL_CODE_VERIFICATION]: null,
  [WithdrawalActionTypeIndia.GET_WITHDRAWAL_HISTORY]: null,
  // #endregion

  // #region Signal Trading
  [SignalTradingActionType.GET_SOURCES]: null,
  [SignalTradingActionType.CREATE_WEBHOOK]: null,
  [SignalTradingActionType.DELETE_WEBHOOK]: null,
  [SignalTradingActionType.GET_ACTIVE_WEBHOOKS]: null,
  [SignalTradingActionType.GET_VERIFICATION_CODE]: null,
  [SignalTradingActionType.GET_USER_TRADING_WEBHOOK]: null,
};

const ACCOUNT_ACTION_TYPES = keyMirror(ActionTypes);

const ACCOUNT_SECURITY_MFA_ACTIVE_TAB = 'ACCOUNT_SECURITY_MFA_ACTIVE_TAB';

export default ACCOUNT_ACTION_TYPES;
export { ACCOUNT_SECURITY_MFA_ACTIVE_TAB };
