const OPTIONS_CHAIN_VIEW_TYPES = {
  ALL: 'all',
  CALL: 'call',
  PUT: 'put',
  T_SHAPE: 'tShape',
} as const;

const T_SHAPE_VIEW_TYPES = {
  PRICE: 'price',
  OI_AND_VOLUMNE: 'oiAndVol',
  GREEKS: 'greeks',
} as const;

export { OPTIONS_CHAIN_VIEW_TYPES, T_SHAPE_VIEW_TYPES };
