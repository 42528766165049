import { createSelector } from 'reselect';

import { IL2Orderbook, IBuySell } from 'reducers/l2Orderbook';
import IState from 'types/Istore';

const l2OrderbookSelector = (state: IState) => state.l2Orderbook;

const l2OrderbookSequenceNumberSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => l2Orderbook.last_sequence_no
);

const l2OrderbookErrorSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => l2Orderbook.error
);

const l2BidsSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => {
    return l2Orderbook.buy;
  }
);

const l2AsksSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => l2Orderbook.sell
);

const mobileOverlayL2AsksSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) =>
    [...l2Orderbook.sell].sort((a, b) => Number(a.price) - Number(b.price))
);

const l2PriceClubbingSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => l2Orderbook.selectedPriceClubbingValue
);

const l2SortedBidsSelector = createSelector([l2BidsSelector], (bids: IBuySell[]) => {
  // @ts-ignore
  const sortedBook = [...bids].sort((a, b) => Number(b.price) - Number(a.price));
  return sortedBook;
});

const l2SortedAsksSelector = createSelector([l2AsksSelector], (asks: IBuySell[]) => {
  // @ts-ignore
  const sortedBook = [...asks].sort((a, b) => Number(a.price) - Number(b.price));
  return sortedBook;
});

const selectedProductBestBidPriceSelector = createSelector(
  [l2SortedBidsSelector],
  (bids: IBuySell[]) => {
    const bestBidPrice = bids.length ? bids[0].price : '';
    return bestBidPrice;
  }
);

const selectedProductBestBidSizeSelector = createSelector(
  [l2SortedBidsSelector],
  (bids: IBuySell[]) => {
    const bestBidSize = bids.length ? bids[0].size : '';
    return bestBidSize;
  }
);

const selectedProductBestAskSizeSelector = createSelector(
  [l2SortedAsksSelector],
  (asks: IBuySell[]) => {
    const bestAskPrice = asks.length ? asks[0].size : '';
    return bestAskPrice;
  }
);

const l2AsksWithDepthSelector = createSelector(
  [l2SortedAsksSelector],
  (asks: IBuySell[]) => asks
);

const l2BidsWithDepthSelector = createSelector(
  [l2SortedBidsSelector],
  (bids: IBuySell[]) => bids
);

const l2OrderbookSelectorWithDepth = createSelector(
  [l2BidsWithDepthSelector, l2AsksWithDepthSelector],
  (buyBook, sellBook) => ({ buy: buyBook, sell: sellBook })
);

const selectedProductBestAskPriceSelector = createSelector(
  [l2SortedAsksSelector],
  (asks: IBuySell[]) => {
    const bestAskPrice = asks.length ? asks[0]?.price : '';
    return bestAskPrice;
  }
);

const l2SpreadValueSelector = createSelector([l2OrderbookSelector], l2Orderbook =>
  Number(l2Orderbook?.spread)
);

const l2PercentageSpreadValueSelector = createSelector(
  [l2OrderbookSelector],
  l2Orderbook => Number(l2Orderbook?.percentage_spread)
);

const l2BestBidsSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => {
    return l2Orderbook.bestBid;
  }
);

const l2BestAsksSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => l2Orderbook.bestAsk
);

const l2BestBidsSizeSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => {
    return l2Orderbook.bestBidSize;
  }
);

const l2BestAsksSizeSelector = createSelector(
  l2OrderbookSelector,
  (l2Orderbook: IL2Orderbook) => l2Orderbook.bestAskSize
);

export {
  l2OrderbookSelector,
  l2OrderbookSequenceNumberSelector,
  l2OrderbookErrorSelector,
  selectedProductBestBidPriceSelector,
  selectedProductBestBidSizeSelector,
  selectedProductBestAskSizeSelector,
  l2AsksWithDepthSelector,
  l2BidsWithDepthSelector,
  l2OrderbookSelectorWithDepth,
  selectedProductBestAskPriceSelector,
  l2PriceClubbingSelector,
  l2AsksSelector,
  l2BidsSelector,
  // l2SortedBidsSelector,
  // l2SortedAsksSelector,
  l2BestBidsSelector,
  l2BestAsksSelector,
  l2BestBidsSizeSelector,
  l2BestAsksSizeSelector,
  mobileOverlayL2AsksSelector,
  l2SpreadValueSelector,
  l2PercentageSpreadValueSelector,
};
