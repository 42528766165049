import { matchPath } from 'react-router';

import { any } from 'helpers/ramda';
import { PostMessageToMobileApp } from 'types/PostMessageToMobileApp';

// Check whether to render a component on a particular routes or not.
export const showComponentOnSelectedRoute = matchRoutes => location => {
  return any(
    path => matchPath(location.pathname as string, path as string) !== null,
    matchRoutes
  );
};

/**
 * @description This function is used to post a message to the mobile app.
 * @param messageType - The type of message to post.
 * @param payload - The payload to post.
 */
export const postMessageToMobileApp: PostMessageToMobileApp = (
  messageType,
  payload = {} as never
) => {
  try {
    if ((window as any).isRNWebView) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          messageType,
          payload,
        })
      );
    }
  } catch (error) {}
};
