/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */

import { produce } from 'immer';

import { UPDATE_ACTIVE_WALLET_TYPE } from 'actionTypes/withdrawal';
import { WITHDRAW_WALLET_TYPES } from 'constants/constants';
import type { WithdrawalWalletType } from 'types/withdrawal';

export interface WithdrawalState {
  activeWalletType: WithdrawalWalletType;
}

const initialState: WithdrawalState = {
  activeWalletType: WITHDRAW_WALLET_TYPES.TRADING,
};

const withdrawalReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_WALLET_TYPE:
      draft.activeWalletType = action.payload;
      break;
    default:
      return draft;
  }
});

export { withdrawalReducer };
