import { ungzip } from 'pako';
import { combineEpics, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  share,
  takeUntil,
} from 'rxjs/operators';

import {
  SUBSCRIBE_BASKET_ORDER_ORDERBOOK,
  UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK,
} from 'actionTypes/basketorders';
import { L2OrderbookActionTypes } from 'actionTypes/l2Orderbook';
import {
  SUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  SUBSCRIBE_L2_ORDERBOOK,
  UNSUBSCRIBE_ALL,
  UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  UNSUBSCRIBE_L2_ORDERBOOK,
  UNSUBSCRIBE_OB_RT,
} from 'actionTypes/socket';
import { isEmpty } from 'helpers/utils';
import {
  updateBasketOrderOrderbook,
  updateEasyOptionsOrderbook,
} from 'variableStore/actions';

import { getComputedOrderbookFromSocket } from '../reduxInWorker/computeOrderbook';
import { activeSubscriptions, logToSentry, payloadGenerator, wsSubject } from './socket';

const stringToBase64Buffer = input => {
  // Decode Base64 string to binary data
  const binaryString = atob(input);

  // Create a Uint8Array buffer from the binary string
  const buffer = new Uint8Array(binaryString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryString.length; i++) {
    buffer[i] = binaryString.charCodeAt(i);
  }

  return buffer;
};

const decompress = input => {
  const decompressedString = ungzip(stringToBase64Buffer(input), { to: 'string' });
  return JSON.parse(decompressedString);
};

const l2OrderbookChannel = symbol => {
  return wsSubject
    .multiplex(
      () => payloadGenerator('subscribe', 'c_l2_orderbook', [symbol]),
      () => {
        activeSubscriptions.delete(symbol);
        return payloadGenerator('unsubscribe', 'c_l2_orderbook', [symbol]);
      },
      message => {
        if (message.type === 'c_l2_orderbook') {
          const data = decompress(message.d);

          return data.symbol === symbol;
        }

        return false;
      }
    )
    .pipe(
      share(), // Share the subscription
      catchError(err => {
        console.error('coming in Error in l2OrderbookChannel:', err);
        return EMPTY;
      })
    );
};

const subscribeL2OrderbookEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SUBSCRIBE_L2_ORDERBOOK),
    mergeMap(action => {
      const symbol = action.payload;

      // Check if already subscribed
      if (activeSubscriptions.has(symbol)) {
        return EMPTY;
      }

      // Mark as subscribed
      activeSubscriptions.add(symbol);

      return l2OrderbookChannel(symbol).pipe(
        takeUntil(
          action$.ofType(UNSUBSCRIBE_OB_RT, UNSUBSCRIBE_L2_ORDERBOOK, UNSUBSCRIBE_ALL)
        )
      );
    }),
    map(message => getComputedOrderbookFromSocket(decompress(message?.d), state$)),
    map(payload => ({ type: L2OrderbookActionTypes.SNAPSHOT, payload })),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const subscribeBasketOrderOrderbookEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SUBSCRIBE_BASKET_ORDER_ORDERBOOK),
    mergeMap(action => {
      return l2OrderbookChannel(action.payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK, UNSUBSCRIBE_ALL)),
        catchError(err => {
          logToSentry(err);
          return EMPTY;
        })
      );
    }),
    map(message => updateBasketOrderOrderbook(decompress(message?.d))),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const subscribeEasyOptionsL2OrderbookEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SUBSCRIBE_EASY_OPTIONS_ORDERBOOK),
    mergeMap(action => {
      return l2OrderbookChannel(action.payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK, UNSUBSCRIBE_ALL)),
        catchError(err => {
          logToSentry(err);
          return EMPTY;
        })
      );
    }),
    filter(data => {
      return !isEmpty(data.sell);
    }),
    distinctUntilKeyChanged('last_updated_at'),
    map(message => updateEasyOptionsOrderbook(decompress(message?.d))),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

const l2OrderbookEpic = combineEpics(
  subscribeL2OrderbookEpic,
  subscribeBasketOrderOrderbookEpic,
  subscribeEasyOptionsL2OrderbookEpic
);

export { l2OrderbookEpic };
