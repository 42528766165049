import { useRef } from 'react';

import { TypedUseSelectorHook, useDispatch, useSelector, Selector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import StoreProxy from 'storeProxy';
import IState from 'types/Istore';

export type AppDispatch = typeof StoreProxy.dispatch &
  Dispatch &
  ThunkDispatch<IState, null, AnyAction> &
  ((action: AnyAction) => Promise<any>);
/**
 * @summary Use throughout the app instead of plain `useDispatch` and `useSelector`
 */
export const useAppSelector: TypedUseSelectorHook<IState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

type IParams = string | number | Record<string, any>;
/**
 * Created to get values from selectors upon events like on click
 * @param selector reselect memoised selector
 * @returns same selector with state in param
 */
export const useAppSelectorOnEvent = <TSelected = unknown>(
  selector: Selector<IState, TSelected, IParams>
) => {
  const selectorRef = useRef(selector);

  const getStateValue = (params?: IParams): TSelected => {
    const state = StoreProxy?.getState?.();
    // @ts-expect-error params type is never. I don't know the build time implications.
    return selectorRef.current(state, params);
  };

  return getStateValue;
};
